import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
  shouldContinueSignUp: false,
  creatorTypes: [],
  collaboratorsTypes: [],
  communityStatus: [],
  activeSkillState: null,
  userSkills: [],
  ageRanges: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.value = action.payload;
    },
    setShouldContinueSignUp: (state, action) => {
      state.shouldContinueSignUp = action.payload;
    },
    setCreatorTypes: (state, action) => {
      state.creatorTypes = action.payload;
    },
    insertIntoCreatorTypes: (state, action) => {
      state.creatorTypes = [...state.creatorTypes, action.payload];
    },
    setCollaboratorsTypes: (state, action) => {
      state.collaboratorsTypes = action.payload;
    },
    insertIntoCollaboratorsTypes: (state, action) => {
      state.collaboratorsTypes = [...state.collaboratorsTypes, action.payload];
    },
    setCommunityStatus: (state, action) => {
      state.communityStatus = action.payload;
    },
    insertIntoCommunityStatus: (state, action) => {
      state.communityStatus = [...state.communityStatus, action.payload];
    },
    setActiveSkillState: (state, action) => {
      state.activeSkillState = action.payload;
    },
    setUserSkills: (state, action) => {
      state.userSkills = action.payload;
    },
    insertIntoUserSkills: (state, action) => {
      state.userSkills = [...state.userSkills, action.payload];
    },
    setAgeRanges: (state, action) => {
      state.ageRanges = action.payload;
    },
    insertIntoAgeRanges: (state, action) => {
      state.ageRanges = [...state.ageRanges, action.payload];
    },
  },
});

export const {
  setUser,
  setShouldContinueSignUp,
  setCreatorTypes,
  insertIntoCreatorTypes,
  setCollaboratorsTypes,
  insertIntoCollaboratorsTypes,
  setCommunityStatus,
  insertIntoCommunityStatus,
  setActiveSkillState,
  setUserSkills,
  insertIntoUserSkills,
  setAgeRanges,
  insertIntoAgeRanges,
} = userSlice.actions;

export default userSlice.reducer;
