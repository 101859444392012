import { useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import CarouselComponent from "./components/carousel";
import LoginButton from "./components/loginButton";
import styles from "./style.module.css";
import useProtectedAuth from "../../hooks/useProtectedAuth";
import Loading from "../../components/loading/default";
import gredientImage from "../../assets/images/login/start-screen-gradient.png";
import LynxLogo from "../../assets/images/logos/Color=White, Size=Large.svg";

function Login() {
  useProtectedAuth();
  const location = useLocation();

  const { pageLoading } = useSelector((state) => state.isLoading);

  useEffect(() => {
    const shouldStoreQueries = location.search.split("=")[0] !== "?code";
    if (localStorage.getItem("starter-queries") === "") {
      if (shouldStoreQueries) {
        localStorage.setItem("starter-queries", location.search);
      }
    }
  }, []);

  if (pageLoading) return <Loading />;

  return (
    <Box
      className={styles.gredient}
      sx={{
        backgroundImage: `url(${gredientImage})`,
      }}
    >
      <Stack
        className={styles.register}
        spacing={{
          xs: 1,
        }}
      >
        <Box className={styles.logoContainer}>
          <img
            src={LynxLogo}
            className={styles.logo}
            alt="Logo"
            width="100"
            height="100"
          />
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "18px",
              fontWeight: "400",
              fontStyle: "italic",
            }}
          >
            Indie Creator Collabs
          </Typography>
        </Box>

        <CarouselComponent />

        <LoginButton />
      </Stack>
    </Box>
  );
}

export default Login;
