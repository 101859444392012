import * as Sentry from "@sentry/react";
import { Route, Routes } from "react-router-dom";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { useTheme } from "./context/themeContext";
import { useAuth0 } from "@auth0/auth0-react";
import "./index.css";

import Login from "./pages/login";
import Signup from "./pages/signup";
import Onboarding from "./pages/onboarding";

import Profile from "./pages/profileView/profile";
import AboutEdit from "./pages/profileView/aboutMe/aboutEdit";
import MakeLynxBetter from "./pages/profileView/feedback/makeLynxBetter";
import HelpWithClassification from "./pages/profileView/feedback/helpWithClassificaton";

import EditSkill from "./pages/profileView/my-skills/components/edit-skill";
import AddSkill from "./pages/profileView/my-skills/components/add-skill";

import Discover from "./pages/discover";
import SearchResult from "./pages/discover/results";

import ProjectLanding from "./pages/projects-view/landing";
import BoardSubmitionForm from "./pages/projects-view/post-project/board-submission-form";
import JoinAProject from "./pages/projects-view/join-project";
import ProjectManage from "./pages/projects-view/manage-project";
import ProjectDetails from "./pages/projects-view/join-project/components/project-details";
import EditProject from "./pages/projects-view/edit-project";

import Settings from "./pages/settings";
import UserRatings from "./pages/settings/user-ratings";
import EmailPreferences from "./pages/settings/email-preferences";

import PublicProfileView from "./pages/public-profile-view";

import BottomNavBar from "./components/footer";
import HomeFooter from "./components/home-footer";

function App() {
  const { isLoading, error } = useAuth0();
  const { theme, darkMode } = useTheme();

  return (
    <main
      className="column"
      style={{
        backgroundColor: darkMode ? "#fff" : "#040d12",
      }}
    >
      <Box
        className="app"
        sx={{
          width: {
            xs: "100%",
            md: "400px",
          },
        }}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {!error && !isLoading && (
            <>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="onboarding" element={<Onboarding />} />

                <Route path="profile" element={<Profile />} />
                <Route path="about-edit" element={<AboutEdit />} />
                <Route path="make-lynx-better" element={<MakeLynxBetter />} />
                {/* <Route
                  path="help-with-classification"
                  element={<HelpWithClassification />}
                /> */}

                {/* <Route path="editSkill/:id" element={<EditSkill />} /> */}
                {/* <Route path="add-skill" element={<AddSkill />} /> */}

                {/* <Route path="discover" element={<Discover />} /> */}
                {/* <Route path="discover/results" element={<SearchResult />} /> */}

                {/* <Route path="projects" element={<ProjectLanding />} /> */}
                {/* <Route
                  path="project-board-submission"
                  element={<BoardSubmitionForm />}
                /> */}
                {/* <Route path="project-join" element={<JoinAProject />} /> */}
                {/* <Route path="project-manage" element={<ProjectManage />} /> */}
                {/* <Route path="projects/:id" element={<ProjectDetails />} /> */}
                {/* <Route path="projects/:id/edit" element={<EditProject />} /> */}

                <Route path="settings" element={<Settings />} />
                {/* <Route path="user-ratings" element={<UserRatings />} /> */}
                {/* <Route
                  path="email-preferences"
                  element={<EmailPreferences />}
                /> */}

                {/* <Route path="creators/:id" element={<PublicProfileView />} /> */}

                <Route path="*" element={<h1>404 Page not found</h1>} />
              </Routes>

              <BottomNavBar />
              <HomeFooter />
            </>
          )}
        </ThemeProvider>
      </Box>
    </main>
  );
}

export default Sentry.withProfiler(App);
