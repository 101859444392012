import React, { useEffect } from "react";
import styles from "./style.module.css";
import { Box, Modal } from "@mui/material";
import { useTheme } from "../../../../../../../../context/themeContext";
import { resizeImage } from "../../../../../../../../helpers/resizeImage";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileImageData } from "../../../../../../../../store/slices/profile/profileImageSlice";
import Head from "./components/head";
import Content from "./components/content";
import { updateInvalid } from "../../../../../../../../store/slices/infoSlice";

const DialogBox = () => {
  const { theme } = useTheme();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const {
    data: { isProfileImageModalOpen },
  } = useSelector((state) => state.profileImage);
  let profileImage = user?.profileImage;
  useEffect(() => {
    profileImage = user?.profileImage;
  }, [user]);

  const closeModal = () => {
    dispatch(
      updateProfileImageData({
        selectedProfileImage: null,
        isProfileImageModalOpen: false,
      })
    );
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    const fileSize = selectedFile?.size / 1024 / 1024;
    if (fileSize > 2) {
      dispatch(
        updateProfileImageData({
          isProfileImageModalOpen: false,
          selectedProfileImage: null,
        })
      );
      dispatch(
        updateInvalid({
          isInvalidOpen: true,
          message:
            "Image size should be less than or equal to 2MB. Please upload a smaller image",
        })
      );
      return;
    }
    if (selectedFile) {
      const resizedImageBlob = await resizeImage(selectedFile, 350, 367.5);
      const resizedImageUrl = URL.createObjectURL(resizedImageBlob);
      dispatch(
        updateProfileImageData({ selectedProfileImage: resizedImageUrl })
      );
    }

    dispatch(updateProfileImageData({ isProfileImageModalOpen: false }));
  };

  const handleEdit = () => {
    dispatch(
      updateProfileImageData({
        selectedProfileImage: user?.originalImage,
        isProfileImageModalOpen: false,
      })
    );
  };

  const handleDelete = () => {
    dispatch(
      updateProfileImageData({
        isProfileImageModalOpen: false,
        isProfileImageDeleteOpen: true,
        selectedProfileImage: null,
      })
    );
  };

  return (
    <Box
      className={styles.coverImage}
      sx={{
        display: isProfileImageModalOpen ? "flex" : "none",
        backgroundImage: `url(${profileImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Modal
        open={isProfileImageModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              bgcolor: theme.palette.lightgrey.lightgrey500,
              width: {
                xs: "100%",
                md: "400px",
              },
            }}
            className={styles.dialogBox}
          >
            <Head closeModal={closeModal} />
            <Content
              handleFileChange={handleFileChange}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default DialogBox;
