import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedProjectImage: null,
  originalProjectImage: null,
  croppedImage:
    "https://lynx-react-images.s3.us-east-2.amazonaws.com/project-cover-image-0.48025884403475594-v-1710946773530",
  isModalProjectOpen: false,
  isDeleteProjectImageOpen: false,
};

const projectCoverImageSlice = createSlice({
  name: "projectCoverImage",
  initialState,
  reducers: {
    setSelectedProjectImage: (state, action) => {
      state.selectedProjectImage = action.payload;
    },
    setOriginalProjectImage: (state, action) => {
      state.originalProjectImage = action.payload;
    },
    setCroppedImage: (state, action) => {
      state.croppedImage = action.payload;
    },
    setIsModalProjectOpen: (state, action) => {
      state.isModalProjectOpen = action.payload;
    },
    setIsDeleteProjectImageOpen: (state, action) => {
      state.isDeleteProjectImageOpen = action.payload;
    },
  },
});

export const {
  setSelectedProjectImage,
  setOriginalProjectImage,
  setCroppedImage,
  setIsModalProjectOpen,
  setIsDeleteProjectImageOpen,
} = projectCoverImageSlice.actions;

export default projectCoverImageSlice.reducer;
