import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    priorityCode: "",
    creatorTypes: [],
    collaboratorsTypes: [],
    selectedCollaboratorsTypes: [],
    country: "United States",
    postalCode: "",
    birthDate: "",
    pronouns: "",
    completedInfo: false,
    phoneNumber: "",
    countryCode: "+1",
  },
};

const registerationSlice = createSlice({
  name: "registeration",
  initialState,
  reducers: {
    updateRegistrationData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export const { updateRegistrationData } = registerationSlice.actions;

export default registerationSlice.reducer;
