import React, { useContext, useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import styles from "./style.module.css";
import trash from "../../../assets/images/trash-icons/trash-03.svg";
import { useAuth0 } from "@auth0/auth0-react";
import supabase from "../../../supabase/client";
import { useTheme } from "../../../context/themeContext";
import axios from "axios";
import Loading from "../../../components/loading/default";
import { useDispatch, useSelector } from "react-redux";
import { setIsPageLoading } from "../../../store/slices/loadingSlice";
import { setOpenDeleteConfirmation } from "../../../store/slices/profile/settingsSlice";
import { node_server } from "../../../constants";

const DeleteConfirmation = (props) => {
  const { theme, darkMode } = useTheme();
  const dispatch = useDispatch();
  const { openDeleteConfirmation } = useSelector((state) => state.settings);
  const user = useSelector((state) => state.user.value);
  const { logout } = useAuth0();

  const handleClose = () => {
    dispatch(setOpenDeleteConfirmation(false));
  };
  const handleAccountDelete = async () => {
    dispatch(setOpenDeleteConfirmation(false));
    dispatch(setIsPageLoading(true));

    localStorage.clear();
    const { data, error } = await axios.get(
      `${node_server}/auth/delete-account`,
      { withCredentials: true }
    );

    if (error) console.log(error);
    if (data) {
      window.location.href = "/";
    }
    dispatch(setIsPageLoading(false));
  };

  return (
    <Modal open={openDeleteConfirmation} onClose={handleClose}>
      <Box
        className={styles.delConfirm}
        sx={{
          bgcolor: theme.palette.lightgrey.lightgrey00,
          width: {
            xs: "320px",
            // sm: "360px",
          },
        }}
      >
        <Box className={styles.content}>
          <Box className={styles.frame}>
            <Box className={styles.icon}>
              <img src={trash} alt="delete" />
            </Box>
            <Box className={styles.text}>
              <Typography
                variant="body1"
                component="h4"
                sx={{
                  color: theme.palette.darkgrey.darkgrey600,
                  fontSize: "20px",
                  fontWeight: "700",
                }}
              >
                Do you want delete your account?
              </Typography>
            </Box>
          </Box>
          <Box className={styles.buttons}>
            <Button
              variant="text"
              onClick={handleClose}
              sx={{
                textTransform: "capitalize",
                color: theme.palette.darkgrey.darkgrey300,
                bgcolor: darkMode
                  ? "#3D3D3D"
                  : theme.palette.lightgrey.lightgrey500,
                padding: "8px",
                boxShadow: "none",
                fontSize: "16px",
                width: "120px",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                color: "#FFF",
                bgcolor: theme.palette.danger.red500,
                padding: "8px",
                width: "120px",
                fontSize: "16px",
              }}
              onClick={handleAccountDelete}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default DeleteConfirmation;
