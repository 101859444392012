import { Box, Typography } from "@mui/material";
import styles from "./style.module.css";
import logo from "../../assets/images/logos/LYNX-Logo-Low-Res1.png";
import { useTheme } from "../../context/themeContext";
import { useLocation } from "react-router-dom";

function HomeFooter() {
  const { theme } = useTheme();
  const { pathname } = useLocation();

  if (pathname === "/home" || pathname === "/featured-skill")
    return (
      <Box className={styles.homeFooter}>
        <Box className={styles.container}>
          <Box className={styles.header}>
            <Box className={styles.logo}>
              <img src={logo} width={208.147} alt="" />
            </Box>
            <Box className={styles.navigation}>
              <Box className={styles.mainNavigations}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M3.33466 18.1815C3.8368 18.3337 4.51504 18.3337 5.66797 18.3337H14.3346C15.4876 18.3337 16.1658 18.3337 16.6679 18.1815M3.33466 18.1815C3.22699 18.1489 3.12741 18.1093 3.03299 18.0612C2.56259 17.8215 2.18014 17.439 1.94045 16.9686C1.66797 16.4339 1.66797 15.7338 1.66797 14.3337V5.66699C1.66797 4.26686 1.66797 3.5668 1.94045 3.03202C2.18014 2.56161 2.56259 2.17916 3.03299 1.93948C3.56777 1.66699 4.26784 1.66699 5.66797 1.66699H14.3346C15.7348 1.66699 16.4348 1.66699 16.9696 1.93948C17.44 2.17916 17.8225 2.56161 18.0622 3.03202C18.3346 3.5668 18.3346 4.26686 18.3346 5.66699V14.3337C18.3346 15.7338 18.3346 16.4339 18.0622 16.9686C17.8225 17.439 17.44 17.8215 16.9696 18.0612C16.8752 18.1093 16.7756 18.1489 16.6679 18.1815M3.33466 18.1815C3.33493 17.5071 3.33897 17.1502 3.39868 16.85C3.66171 15.5277 4.69537 14.4941 6.01767 14.231C6.33966 14.167 6.72688 14.167 7.5013 14.167H12.5013C13.2757 14.167 13.6629 14.167 13.9849 14.231C15.3072 14.4941 16.3409 15.5277 16.6039 16.85C16.6636 17.1502 16.6677 17.5071 16.6679 18.1815M13.3346 7.91699C13.3346 9.75794 11.8423 11.2503 10.0013 11.2503C8.16035 11.2503 6.66797 9.75794 6.66797 7.91699C6.66797 6.07604 8.16035 4.58366 10.0013 4.58366C11.8423 4.58366 13.3346 6.07604 13.3346 7.91699Z"
                    stroke="#9C9A9D"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <Typography
                  className={styles.navText}
                  sx={{ color: theme.palette.darkgrey.darkgrey300 }}
                >
                  My Creator Card
                </Typography>
              </Box>
              <Box className={styles.mainNavigations}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M11.6654 9.16699H6.66536M8.33203 12.5003H6.66536M13.332 5.83366H6.66536M16.6654 8.75033V5.66699C16.6654 4.26686 16.6654 3.5668 16.3929 3.03202C16.1532 2.56161 15.7707 2.17916 15.3003 1.93948C14.7656 1.66699 14.0655 1.66699 12.6654 1.66699H7.33203C5.9319 1.66699 5.23183 1.66699 4.69705 1.93948C4.22665 2.17916 3.8442 2.56161 3.60451 3.03202C3.33203 3.5668 3.33203 4.26686 3.33203 5.66699V14.3337C3.33203 15.7338 3.33203 16.4339 3.60451 16.9686C3.8442 17.439 4.22665 17.8215 4.69705 18.0612C5.23183 18.3337 5.9319 18.3337 7.33203 18.3337H9.58203M18.332 18.3337L17.082 17.0837M17.9154 15.0003C17.9154 16.6112 16.6095 17.917 14.9987 17.917C13.3879 17.917 12.082 16.6112 12.082 15.0003C12.082 13.3895 13.3879 12.0837 14.9987 12.0837C16.6095 12.0837 17.9154 13.3895 17.9154 15.0003Z"
                    stroke="#9C9A9D"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <Typography
                  className={styles.navText}
                  sx={{ color: theme.palette.darkgrey.darkgrey300 }}
                >
                  Discover
                </Typography>
              </Box>
              <Box className={styles.mainNavigations}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M1.66797 9.99997L9.70316 14.0176C9.81248 14.0722 9.86714 14.0996 9.92447 14.1103C9.97525 14.1198 10.0274 14.1198 10.0781 14.1103C10.1355 14.0996 10.1901 14.0722 10.2994 14.0176L18.3346 9.99997M1.66797 14.1666L9.70316 18.1842C9.81248 18.2389 9.86714 18.2662 9.92447 18.277C9.97525 18.2865 10.0274 18.2865 10.0781 18.277C10.1355 18.2662 10.1901 18.2389 10.2994 18.1842L18.3346 14.1666M1.66797 5.83331L9.70316 1.81571C9.81248 1.76105 9.86714 1.73372 9.92447 1.72297C9.97525 1.71344 10.0274 1.71344 10.0781 1.72297C10.1355 1.73372 10.1901 1.76105 10.2994 1.81571L18.3346 5.83331L10.2994 9.8509C10.1901 9.90556 10.1355 9.93289 10.0781 9.94365C10.0274 9.95317 9.97525 9.95317 9.92447 9.94365C9.86714 9.93289 9.81248 9.90556 9.70316 9.8509L1.66797 5.83331Z"
                    stroke="#9C9A9D"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <Typography
                  className={styles.navText}
                  sx={{ color: theme.palette.darkgrey.darkgrey300 }}
                >
                  Projects
                </Typography>
              </Box>
            </Box>
          </Box>
          <hr className={styles.spliter} />
          <Typography
            className={styles.copyRight}
            sx={{ color: theme.palette.darkgrey.darkgrey300 }}
          >
            Copyright by LYNX | Crowd Power Ventures
          </Typography>
        </Box>
      </Box>
    );
}

export default HomeFooter;
