import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    selectedProfileImage: null,
    croppedProfileImage: null,
    isProfileImageModalOpen: false,
    isProfileImageDeleteOpen: false,
  },
};

const profileImageSlice = createSlice({
  name: "profileImage",
  initialState,
  reducers: {
    updateProfileImageData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    // setSelectedProfileImage: (state, action) => {
    //   state.selectedProfileImage = action.payload;
    // },
    // setCroppedProfileImage: (state, action) => {
    //   state.croppedProfileImage = action.payload;
    // },
    // setIsProfileImageModalOpen: (state, action) => {
    //   state.isProfileImageModalOpen = action.payload;
    // },
    // setIsProfileImageDeleteOpen: (state, action) => {
    //   state.isProfileImageDeleteOpen = action.payload;
    // },
  },
});

export const {
  updateProfileImageData,
  // setSelectedProfileImage,
  // setCroppedProfileImage,
  // setIsProfileImageModalOpen,
  // setIsProfileImageDeleteOpen,
} = profileImageSlice.actions;

export default profileImageSlice.reducer;
