import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
  skills: [],
  hostedProjects: [],
  likedProjects: [],
  givenShoutOuts: [],
  receivedShoutOuts: [],
};

const publicUserSlice = createSlice({
  name: "publicUser",
  initialState,
  reducers: {
    setPublicUser: (state, action) => {
      state.value = action.payload;
    },
    setPublicSkills: (state, action) => {
      state.skills = action.payload;
    },
    setPublicHostedProjects: (state, action) => {
      state.hostedProjects = action.payload;
    },
    setPublicLikedProjects: (state, action) => {
      state.likedProjects = action.payload;
    },
    setPublicGivenShoutOuts: (state, action) => {
      state.givenShoutOuts = action.payload;
    },
    setPublicReceivedShoutOuts: (state, action) => {
      state.receivedShoutOuts = action.payload;
    },
  },
});

export const {
  setPublicUser,
  setPublicSkills,
  setPublicHostedProjects,
  setPublicLikedProjects,
  setPublicGivenShoutOuts,
  setPublicReceivedShoutOuts,
} = publicUserSlice.actions;

export default publicUserSlice.reducer;
