import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsUserProfileComplete,
  updateProfileCompleteData,
} from "../store/slices/profile/profileCompleteSlice";
import { setUser } from "../store/slices/userSlice";
import axios from "axios";
import { node_server } from "../constants";

const useProfileComplete = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const {
    data: { imageAdded, skillComplete, collaborator, profileComplete },
    isUserProfileComplete,
  } = useSelector((state) => state.profileComplete);

  const checkProfileComplete = async () => {
    dispatch(setIsUserProfileComplete(user.isProfileComplete));
    dispatch(
      updateProfileCompleteData({ profileComplete: user.isProfileComplete })
    );
  };

  const handleProfileComplete = () => {
    if (imageAdded && skillComplete && collaborator) {
      dispatch(updateProfileCompleteData({ profileComplete: true }));
    } else {
      dispatch(updateProfileCompleteData({ profileComplete: false }));
    }
  };

  const updateProfileComplete = async () => {
    const { data, error } = await axios.post(
      `${node_server}/api/user/update`,
      {
        isProfileComplete: profileComplete,
      },
      {
        withCredentials: true,
      }
    );

    if (error) console.log(error);
    if (data) {
      dispatch(setIsUserProfileComplete(data.isProfileComplete));
      dispatch(setUser(data));
    }
  };

  useEffect(() => {
    if (!user) return;
    checkProfileComplete();
  }, []);

  useEffect(() => {
    if (!user) return;
    if (profileComplete === isUserProfileComplete) return;
    updateProfileComplete();
  }, [profileComplete]);

  useEffect(() => {
    handleProfileComplete();
  }, [imageAdded, skillComplete, collaborator]);
};

export default useProfileComplete;
