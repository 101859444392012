import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import styles from "./style.module.css";
import AboutMe from "../../../../aboutMe";
import MySkills from "../../../../my-skills";
import Projects from "../../../../projects";
import ShoutOuts from "../../../../shout-outs";
import { useSelector } from "react-redux";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;
  const user = useSelector((state) => state.user.value);
  if (!user) return <div>loading...</div>;

  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            sx={{
              width: {
                xs: "100%",
              },
            }}
          >
            {children}
          </Box>
        )}
      </div>
    </>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const DisplayTabs = ({ value }) => {
  return (
    <Box className={styles.displayTabs}>
      <CustomTabPanel value={value} index={0}>
        <AboutMe />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {/* <MySkills /> */}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {/* <Projects /> */}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        {/* <ShoutOuts /> */}
      </CustomTabPanel>
    </Box>
  );
};

export default DisplayTabs;
