import { Box, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../context/themeContext";
import { useSelector } from "react-redux";
import LoadingButton from "../../../../../components/loading/button";
import GenerationalTag from "./components/generationalTag";
import useInfoBoxs from "../../../../../hooks/useInfoBoxs";
import styles from "./style.module.css";

const UserAgeRanges = () => {
  const { theme } = useTheme();
  const userAgeRanges = useSelector((state) => state.user.ageRanges);
  const infoBoxes = useInfoBoxs();

  return (
    <Box className={styles.content}>
      <Typography
        variant="body1"
        sx={{
          color: theme.palette.darkgrey.darkgrey600,
          fontSize: "18px",
          fontWeight: "700",
        }}
      >
        Age Range
      </Typography>
      <Box
        className={styles.tags}
        sx={{
          flexWrap: "wrap",
        }}
      >
        {userAgeRanges.length === 0 ? (
          <LoadingButton />
        ) : userAgeRanges.length === 0 ? (
          <Typography
            variant="body2"
            sx={{ color: theme.palette.darkgrey.darkgrey300 }}
          >
            Edit your profile to add age ranges !
          </Typography>
        ) : (
          userAgeRanges.map((item) => {
            return (
              <GenerationalTag
                ageCategory={item?.ageCategory}
                key={item?.ageCategory}
                infoText={
                  infoBoxes.find(
                    (info) => info.name === "Gen" + item?.ageCategory
                  )?.content
                }
              />
            );
          })
        )}
      </Box>
    </Box>
  );
};

export default UserAgeRanges;
