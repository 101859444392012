import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Box, Drawer, IconButton } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import UseLocalStorageState from "../../../hooks/useLocalStorageState";
import ProfileImage from "./components/profileImage";
import UploadProfileImage from "./components/profileImage/upload-profile-image";
import EditProfileImage from "./components/profileImage/edit";
import DeleteProfileImage from "./components/profileImage/delete";
import ProfileTabs from "./components/profileTabs";
import DisplayTabs from "./components/profileTabs/displayTabs";
import ProfileName from "./components/profileName";

import UploadSkillImage from "../my-skills/components/skills-cover-image/upload-profile-image";
import EditSkillImage from "../my-skills/components/skills-cover-image/edit";
import DeleteSkillImage from "../my-skills/components/skills-cover-image/delete";
import Loading from "../../../components/loading/default";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "../../../context/themeContext";
import { useDispatch, useSelector } from "react-redux";
import useProtectedAuth from "../../../hooks/useProtectedAuth";
import useManageProjects from "../../../hooks/useManageProjects";
import checkProfileComplete from "../../../helpers/checkProfileComplete";
import { setUser } from "../../../store/slices/userSlice";
import DrawerList from "./components/drawerList";
import { defaultProfileImageSquare } from "../../../constants";
import Invalid from "../../../components/common/Invalid";

function Profile() {
  useProtectedAuth();
  const { theme } = useTheme();
  const { pageLoading } = useSelector((state) => state.isLoading);
  const [value, setValue] = UseLocalStorageState("myprofile-bar", 1);
  const [hideIndcator, setHideIndcator] = UseLocalStorageState(
    "hideIndcator",
    false
  );
  const {
    data: { selectedProfileImage, isProfileImageDeleteOpen },
  } = useSelector((state) => state.profileImage);
  const { selectedSkillImage, isDeleteSkillImageOpen } = useSelector(
    (state) => state.skillImage
  );

  const { toggleDrawer } = useManageProjects();
  const { isDrawerOpen } = useSelector((state) => state.manageProjects);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);

  const [searchParams] = useSearchParams();
  const tabName = useState(searchParams.get("tab"));

  useEffect(() => {
    const checKProfile = async () => {
      await checkProfileComplete(user, (value) => {
        dispatch(setUser(value));
      });
    };

    if (user) checKProfile();
  }, []);

  useEffect(() => {
    if (tabName === "shoutouts") {
      setValue(3);
      setHideIndcator(true);
    } else if (tabName === "projects") {
      setValue(2);
    } else if (tabName === "skills") {
      setValue(1);
    } else if (tabName === "about-me") {
      setValue(0);
    }
  }, []);

  const data = {
    value,
    setValue,
  };

  const clearSearchParams = () => {
    // Create a new URL object based on the current URL
    const url = new URL(window.location.href);

    // Clear all search parameters
    url.search = "";

    // Use history.replaceState to update the URL without reloading the page
    window.history.replaceState({}, document.title, url);
  };

  const handleShutClick = () => {
    setValue(3);
    setHideIndcator(true);
    clearSearchParams();
  };

  if (pageLoading) {
    return <Loading />;
  }

  if (!user) {
    return <Loading />;
  }

  return (
    <Box className={styles.parent}>
      <Box
        className={styles.cardDetails}
        sx={{
          bgcolor: theme.palette.lightgrey.lightgrey600,
        }}
      >
        <Invalid />
        {selectedProfileImage &&
          selectedProfileImage !== defaultProfileImageSquare && (
            <EditProfileImage />
          )}
        {isProfileImageDeleteOpen && <DeleteProfileImage />}
        {selectedSkillImage && <EditSkillImage />}
        {isDeleteSkillImageOpen && <DeleteSkillImage />}
        <Box className={styles.myCardDetails}>
          <Box className={styles.userDetails}>
            <Box className={styles.details}>
              <ProfileImage />
              <ProfileName />
            </Box>
          </Box>
          <Box className={styles.tabs}>
            <ProfileTabs
              data={data}
              isIndecatorHide={hideIndcator}
              setHideIndcator={setHideIndcator}
            />
            <IconButton onClick={toggleDrawer(true)}>
              <MoreHoriz sx={{ color: "#FFF" }} />
            </IconButton>
            <Drawer
              anchor="bottom"
              open={isDrawerOpen}
              onClose={toggleDrawer(false)}
              sx={{
                "& .MuiPaper-root": {
                  maxWidth: {
                    xs: "100%",
                  },
                  md: {
                    maxWidth: "400px !important",
                    left: "50% !important",
                    transform: "translateX(-50%) !important",
                  },
                },
              }}
            >
              <DrawerList
                toggleDrawer={toggleDrawer}
                handleShutClick={handleShutClick}
              />
            </Drawer>
          </Box>
        </Box>
        <DisplayTabs value={value} />
      </Box>
      <UploadProfileImage />
      <UploadSkillImage />
    </Box>
  );
}

export default Profile;
