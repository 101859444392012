import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLogedIn: false,
  status: "",
};

const projectDetailsSlice = createSlice({
  name: "projectDetails",
  initialState,
  reducers: {
    setIsLogedIn: (state, action) => {
      state.isLogedIn = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const { setIsLogedIn, setStatus } = projectDetailsSlice.actions;

export default projectDetailsSlice.reducer;
