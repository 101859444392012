import React, { useEffect } from "react";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setAboutEditCopiedData,
  setAboutEditData,
  updateAboutEditData,
} from "../../../../../../store/slices/profile/aboutEditSlice";
import useCountries from "../../../../../../helpers/getAllCountries";
import { useTheme } from "../../../../../../context/themeContext";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const PersonalDetails = () => {
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const { data } = useSelector((state) => state.aboutEdit);

  const countries = useCountries();
  const countriesNames = countries.map((country) => country.name);

  const handleBirthdate = (e, ElementName) => {
    const formattedDate = moment(e).format("MM-DD-YYYY");
    const birthYear = moment(e).year();
    const currentYear = moment().year();
    if (birthYear > currentYear || String(birthYear).length < 4) {
      dispatch(updateAboutEditData({ [ElementName]: "" }));
      return;
    }
    dispatch(updateAboutEditData({ [ElementName]: formattedDate }));
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    dispatch(updateAboutEditData({ [name]: value }));
  };

  const renderTextField = (
    label,
    name,
    value,
    type = "text",
    isDisabled = false
  ) => {
    return (
      <TextField
        label={label}
        name={name}
        value={value || ""}
        onChange={handleChange}
        variant="outlined"
        disabled={isDisabled}
        fullWidth
        type={type}
      />
    );
  };

  useEffect(() => {
    if (!user) return;
    dispatch(setAboutEditData(user));
    dispatch(setAboutEditCopiedData(user));
  }, [user]);

  return (
    <>
      <Box className={styles.details}>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.darkgrey.darkgrey600,
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Personal Details
        </Typography>
      </Box>

      <Box className={styles.content}>
        {renderTextField("My Real Name", "realName", data.realName)}

        {renderTextField("My Nickname", "nickName", data.nickName)}

        {renderTextField("Tagline", "tagline", data.tagline)}

        {renderTextField(
          "Public Email Address",
          "publicEmail",
          data.publicEmail,
          "email"
        )}

        {renderTextField(
          "Registered Email Address",
          "email",
          data.email,
          "email",
          true
        )}

        {renderTextField("Phone Number", "mobile", data.mobile)}

        <Box sx={{ width: "100%", "&>*": { width: "100%" } }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Birth Date"
              name="birthDate"
              onChange={(e) => handleBirthdate(e, "birthDate")}
              value={moment(data.birthDate)}
            />
          </LocalizationProvider>
        </Box>

        <Autocomplete
          sx={{ bgcolor: theme.palette.lightgrey.lightgrey00 }}
          fullWidth
          options={countriesNames}
          getOptionLabel={(option) => option}
          value={data.country || "United States"}
          onChange={(e, value) => {
            dispatch(updateAboutEditData({ country: value }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Country"
              name="country"
              variant="outlined"
            />
          )}
        />

        {renderTextField("Postal Code", "postalCode", data.postalCode)}

        {renderTextField("City", "city", data.city)}

        <Box
          sx={{
            display: "flex",
            gap: "12px",
            alignSelf: "stretch",
          }}
        >
          {renderTextField("State/Province", "state", data.state)}

          {renderTextField("County/Territory", "county", data.county)}
        </Box>
      </Box>
    </>
  );
};

export default PersonalDetails;
