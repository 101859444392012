import React from "react";
import DialogBox from "./components/dialog-box";

const UploadProfileImage = () => {
  return (
    <React.Fragment>
      <DialogBox />
    </React.Fragment>
  );
};

export default UploadProfileImage;
