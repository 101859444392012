import React, { useState, useRef } from "react";
import { getCroppedImg } from "./components/cropUtils";
import styles from "./style.module.css";
import { Box, Typography } from "@mui/material";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import LoadingButton from "../../../../../../../../components/loading/button";
import { useDispatch, useSelector } from "react-redux";
import { setIsButtonLoading } from "../../../../../../../../store/slices/loadingSlice";

const ASPECT_RATIO = 700 / 735;
const MIN_DIMENSION = 150;

const ImageCropper = ({ onCrop, onCancel }) => {
  const [crop, setCrop] = useState();
  const imgRef = useRef(null);

  const {
    data: { selectedProfileImage },
  } = useSelector((state) => state.profileImage);
  const { buttonLoading } = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );

    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const handleCrop = async () => {
    dispatch(setIsButtonLoading(true));
    const croppedImage = await getCroppedImg(
      imgRef.current,
      convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
    );

    onCrop(croppedImage);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#040d12",
        height: "100%",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: {
            xs: "100%",
            md: "400px",
          },
          height: "100%",
        }}
      >
        {selectedProfileImage && (
          <Box className={styles.crop}>
            <ReactCrop
              crop={crop}
              onChange={(pixelCrop, percentCrop) => {
                setCrop(percentCrop);
              }}
              circularCrop
              keepSelection
              aspect={ASPECT_RATIO}
              minWidth={MIN_DIMENSION}
            >
              <Box
                sx={{
                  maxWidth: "350px",
                  maxHeight: "367.5px",
                  bgcolor: "inherit",
                }}
              >
                <img
                  ref={imgRef}
                  src={selectedProfileImage}
                  alt="upload"
                  crossOrigin="anonymous"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  onLoad={onImageLoad}
                />
              </Box>
            </ReactCrop>
          </Box>
        )}
        <Box className={styles.frame}>
          <Box className={styles.content}>
            <Typography
              variant="body1"
              fontWeight={500}
              onClick={onCancel}
              sx={{
                cursor: "pointer",
              }}
            >
              Cancel
            </Typography>
            <Box
              variant="body1"
              fontWeight={500}
              onClick={handleCrop}
              sx={{
                cursor: "pointer",
              }}
            >
              {buttonLoading ? <LoadingButton /> : "Choose"}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ImageCropper;
