import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../../../context/themeContext";
import { useDispatch, useSelector } from "react-redux";
import { updateMLB } from "../../../../../store/slices/infoSlice";
import axios from "axios";
import { node_server } from "../../../../../constants";
import { setIsButtonLoading } from "../../../../../store/slices/loadingSlice";
import LoadingButton from "../../../../../components/loading/button";

const CreatorType = () => {
  const { theme } = useTheme();
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [isDisabled, setIsdisabled] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { buttonLoading } = useSelector((state) => state.isLoading);

  const handleSubmit = async () => {
    dispatch(setIsButtonLoading(true));
    const { data, error } = await axios.post(
      `${node_server}/api/feedback/tickets`,
      {
        category,
        details: description,
      },
      {
        withCredentials: true,
      }
    );
    if (error) console.log(error);
    if (data) {
      dispatch(updateMLB({ isThanksOpen: true }));
      dispatch(setIsButtonLoading(false));
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (category.length > 0 || description.length > 0) {
      setIsdisabled(false);
    } else {
      setIsdisabled(true);
    }
  }, [buttonLoading, category, description]);

  const radioGroupData = [
    { value: "New feature suggestion" },
    { value: "Suggest a new skill category" },
    { value: "Bug report & platform issues" },
    { value: "Login or registration issue" },
    { value: "Flag inappropriate image or report user" },
    { value: "Trouble with skill classification" },
    { value: "Nominate someone for Creator's Choice Award" },
    { value: "Business partnership proposal" },
    { value: "Other" },
  ];

  return (
    <Box className={styles.creatorType}>
      <Box className={styles.content}>
        <Box className={styles.creatorType2}>
          <Typography
            variant="h4"
            sx={{
              color: theme.palette.darkgrey.darkgrey600,
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            Choose Category
          </Typography>
          <FormControl
            sx={{
              color: theme.palette.darkgrey.darkgrey500,
              "& .MuiFormControlLabel-label": {
                fontSize: "14px",
              },
            }}
          >
            <RadioGroup
              name="Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              {radioGroupData.map((item) => {
                return (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio />}
                    label={item.value}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </Box>
        <Box className={styles.options}>
          <Typography
            variant="body1"
            color={theme.palette.darkgrey.darkgrey600}
          >
            Details
          </Typography>
          <TextField
            sx={{
              background: theme.palette.lightgrey.lightgrey00,
            }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            id="outlined-textarea"
            multiline
            rows={4}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "stretch",
            gap: "16px",
          }}
        >
          <Button
            variant="contained"
            onClick={handleSubmit}
            fullWidth
            disabled={buttonLoading || isDisabled}
          >
            {buttonLoading ? <LoadingButton /> : "Submit"}
          </Button>
          <Button
            onClick={handleCancel}
            variant="text"
            fullWidth
            sx={{
              p: "0px",
              color: theme.palette.darkgrey.darkgrey400,
              textTransform: "capitalize",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreatorType;
