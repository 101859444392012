import React, { useEffect } from "react";
import styles from "./style.module.css";
import { Box, Divider, TextField, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import uploadIcon from "../../../../../../../assets/images/upload-icons/upload-01.svg";
import editIcon from "../../../../../../../assets/images/edit-icons/edit-03.svg";
import deleteIcon from "../../../../../../../assets/images/trash-icons/trash-01.svg";
import { useTheme } from "../../../../../../../context/themeContext";
import { useDispatch, useSelector } from "react-redux";
import useSkillsBar from "../../../../../../../hooks/useSkillsBar";
import {
  setIsDeleteSkillImageOpen,
  setIsModalSkillOpen,
  setSelectedSkillImage,
} from "../../../../../../../store/slices/profile/skillImageSlice";

const DialogBox = () => {
  const { theme } = useTheme();

  const dispatch = useDispatch();
  const { activeSkill } = useSkillsBar();
  const { isModalSkillOpen } = useSelector((state) => state.skillImage);
  let skill_cover_image = activeSkill?.skill_cover_image;
  useEffect(() => {
    skill_cover_image = activeSkill?.skill_cover_image;
  }, [activeSkill]);

  const closeModal = () => {
    dispatch(setIsModalSkillOpen(false));
    dispatch(setSelectedSkillImage(null));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      dispatch(setSelectedSkillImage(URL.createObjectURL(selectedFile)));
    }

    dispatch(setIsModalSkillOpen(false));
  };

  const handleEdit = () => {
    dispatch(setSelectedSkillImage(activeSkill?.original_cover_image));
    dispatch(setIsModalSkillOpen(false));
  };

  const handleDelete = () => {
    dispatch(setIsDeleteSkillImageOpen(true));
    dispatch(setIsModalSkillOpen(false));
    dispatch(setSelectedSkillImage(null));
  };

  const style = {
    bgcolor: theme.palette.lightgrey.lightgrey500,
  };

  return (
    <Box
      className={styles.coverImage}
      sx={{
        display: isModalSkillOpen ? "flex" : "none",
        backgroundImage: `url(${skill_cover_image})`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Modal
        open={isModalSkillOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              bgcolor: theme.palette.lightgrey.lightgrey500,
              width: {
                xs: "100%",
                md: "400px",
              },
            }}
            className={styles.dialogBox}
          >
            <Box sx={style} className={styles.container}>
              <Box sx={style} className={styles.text}>
                <Box sx={style} className={styles.subText}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: theme.palette.darkgrey.darkgrey800,
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    Upload skill cover photo
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: theme.palette.darkgrey.darkgrey500 }}
                  >
                    JPG, GIF, or PNG. Max size of 2MB
                  </Typography>
                </Box>
                <Close
                  onClick={closeModal}
                  sx={{
                    cursor: "pointer",
                    color: theme.palette.darkgrey.darkgrey300,
                  }}
                />
              </Box>
            </Box>
            <Divider />
            <Box sx={style} className={styles.upload}>
              <Box sx={style} className={styles.uploadPhoto}>
                <input
                  type="file"
                  accept="image/*"
                  id="imageInput"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <TextField
                  fullWidth
                  value={"Choose photo from gallery"}
                  onClick={() => document.getElementById("imageInput").click()}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <label htmlFor="imageInput" style={{ cursor: "pointer" }}>
                        <img src={uploadIcon} alt="upload" />
                      </label>
                    ),
                    style: {
                      backgroundColor: theme.palette.lightgrey.lightgrey00,
                      color: theme.palette.darkgrey.darkgrey600,
                      outline: "none",
                    },
                  }}
                />
              </Box>

              <Box
                sx={style}
                style={{ display: skill_cover_image ? "flex" : "none" }}
                className={styles.uploadPhoto}
              >
                <TextField
                  fullWidth
                  value="Edit"
                  onClick={handleEdit}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <label style={{ cursor: "pointer" }}>
                        <img src={editIcon} alt="upload" />
                      </label>
                    ),
                    style: {
                      backgroundColor: theme.palette.lightgrey.lightgrey00,
                      color: theme.palette.darkgrey.darkgrey600,
                      outline: "none",
                    },
                  }}
                />
              </Box>
              <Box
                sx={style}
                style={{ display: skill_cover_image ? "flex" : "none" }}
                className={styles.uploadPhoto}
              >
                <TextField
                  fullWidth
                  value="Delete"
                  onClick={handleDelete}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <label style={{ cursor: "pointer" }}>
                        <img src={deleteIcon} alt="upload" />
                      </label>
                    ),
                    style: {
                      backgroundColor: theme.palette.lightgrey.lightgrey00,
                      color: theme.palette.danger.red500,
                      outline: "none",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default DialogBox;
