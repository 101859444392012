import React from "react";
import { Box, Button } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { useTheme } from "../../../../context/themeContext";

const SubmitButton = ({ clickNext, sx }) => {
  const { theme } = useTheme();

  const defaultStyle = {
    p: "8px 13px 8px 24px",
    justifyContent: "space-between",
    "& span:nth-of-type(1)": {
      background: theme.palette.primary.purpel400,
      height: "2em",
      width: "2em",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "4px",
    },
  };

  return (
    <Box sx={{ p: "1.5rem" }}>
      <Button
        type="submit"
        variant="contained"
        fullWidth
        endIcon={<ChevronRight />}
        sx={sx || defaultStyle}
        onClick={clickNext}
      >
        Next Step
      </Button>
    </Box>
  );
};

export default SubmitButton;
