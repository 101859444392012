import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import styles from "./style.module.css";
import { useTheme } from "../../../context/themeContext";
import BasicTabs from "./components/tabs";
import supabase from "../../../supabase/client";
import { useSelector } from "react-redux";

const ShoutOuts = () => {
  const { theme } = useTheme();
  const user = useSelector((state) => state.user.value);
  const [receivedShoutOuts, setReceivedShoutOuts] = useState([]);
  const [givenShoutOuts, setGivenShoutOuts] = useState([]);

  useEffect(() => {
    const fetchGivenShoutOuts = async () => {
      const { data, error } = await supabase
        .from("given_shoutouts")
        .select()
        .eq("sender_id", user?.id);

      if (error) console.log(error);
      if (data) setGivenShoutOuts(data);
    };

    const fetchReceivedShoutOuts = async () => {
      const { data, error } = await supabase
        .from("received_shoutouts")
        .select()
        .eq("receiver_id", user?.id);

      if (error) console.log(error);
      if (data) setReceivedShoutOuts(data);
    };

    fetchReceivedShoutOuts();
    fetchGivenShoutOuts();
  }, [user]);

  return (
    <Box
      className={styles.newClassName}
      sx={{ backgroundColor: theme.palette.lightgrey.lightgrey600 }}
    >
      <Box className={styles.content}>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.5rem",
            fontWeight: "500",
            textAlign: "centerd",
            display: "flex",
            alignSelf: "stretch",
            alignItems: "center",
            justifyContent: "center",
            color: theme.palette.darkgrey.darkgrey600,
          }}
        >
          Shout Outs
        </Typography>
        <BasicTabs
          receivedShoutOuts={receivedShoutOuts}
          givenShoutOuts={givenShoutOuts}
        />
      </Box>
    </Box>
  );
};

export default ShoutOuts;
