import { useDispatch, useSelector } from "react-redux";
import supabase from "../supabase/client";
import { sendProjectDeletedemail } from "../helpers/emails";
import {
  setApplications,
  setHostedProjects,
  setIsDrawerOpen,
  setLikedProjects,
  setProjectApplicants,
  setProjects,
} from "../store/slices/profile/manageProjectsSlice";
import {
  setPublicHostedProjects,
  setPublicLikedProjects,
} from "../store/slices/profile/publicUserSlice";
import {
  setData,
  setProjectFavorites,
} from "../store/slices/profile/discoverSlice";
import { useLocation } from "react-router-dom";
import useDiscover from "./useDiscover";

function useManageProjects() {
  const user = useSelector((state) => state.user.value);
  const { data, projectFavorites } = useSelector((state) => state.discover);
  useDiscover(true);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isPublic = pathname.includes("creators");

  const toggleDrawer = (newOpen) => () => {
    dispatch(setIsDrawerOpen(newOpen));
  };

  const handleFavorite = async (id, state) => {
    if (state === "add") {
      const { data: newFav, error } = await supabase
        .from("projects_favourite")
        .insert([
          {
            project_id: id,
            user_id: user?.id,
          },
        ])
        .select();

      if (error) {
        console.log(error);
      } else {
        dispatch(setProjectFavorites([...projectFavorites, newFav[0]]));
        dispatch(
          setData(
            data.map((item) =>
              item.project_id === id ? { ...item, is_favourite: true } : item
            )
          )
        );
      }
    } else if (state === "remove") {
      const { error } = await supabase
        .from("projects_favourite")
        .delete()
        .eq("project_id", id)
        .eq("user_id", user?.id);

      if (error) {
        console.log(error);
      } else {
        dispatch(
          setProjectFavorites(
            projectFavorites.filter((item) => item.project_id !== id)
          )
        );
        dispatch(
          setData(
            data.map((item) =>
              item.project_id === id ? { ...item, is_favourite: false } : item
            )
          )
        );
      }
    }
  };

  const fetchFavorite = async (id) => {
    if (user && user?.id) {
      const { data, error } = await supabase
        .from("projects_favourite")
        .select("*")
        .eq("project_id", id)
        .eq("user_id", user?.id);

      if (error) console.log(error);

      return data.length > 0;
    }
    return false;
  };

  const handleDeleteApplicants = async (item) => {
    const { error } = await supabase
      .from("projects_applications")
      .delete()
      .eq("project_id", item.project_id)
      .eq("user_id", item.user_id);

    if (error) console.log(error);
  };

  const handleRemoveApplication = async (project) => {
    const { error } = await supabase
      .from("projects_applications")
      .delete()
      .eq("project_id", project.project_id)
      .eq("user_id", project.user_id);

    if (error) console.log(error);
  };

  const handleProjectDelete = async (project) => {
    const { error } = await supabase
      .from("projects")
      .update({ status: "deleted" })
      .eq("id", project.id);

    if (error) console.log(error);
    await sendProjectDeletedemail(project.id);
  };

  const fetchProjectApplicants = async (project) => {
    if (project) {
      const { data, error } = await supabase
        .from("project_applications_with_user_info")
        .select("*")
        .eq("project_id", project.id);

      if (error) console.log(error);
      if (data) dispatch(setProjectApplicants(data || []));
    }
  };

  const fetchLikedProjects = async (user) => {
    const { data, error } = await supabase
      .from("user_favorite_projects")
      .select()
      .eq("user_id", user?.id);
    if (error) console.log(error);

    if (isPublic) {
      const { data: pendingProjects, error } = await supabase
        .from("projects")
        .select()
        .eq("user_id", user?.id)
        .eq("status", "Under Review");

      if (error) console.log(error);
      if (pendingProjects) {
        const pendingProjectsIds = pendingProjects.map((pend) => pend.id);
        const filteredData = data.filter(
          (item) => !pendingProjectsIds.includes(item.project_id)
        );
        dispatch(setPublicLikedProjects(filteredData));
      }
    } else {
      dispatch(setLikedProjects(data));
    }
  };

  const fetchHostedProjects = async (user) => {
    if (isPublic) {
      const { data, error } = await supabase
        .from("projects")
        .select()
        .eq("user_id", user?.id)
        .in("status", ["Completed", "Accepted", "Approved"]);

      if (error) console.log(error);
      if (data) dispatch(setPublicHostedProjects(data));
    } else {
      const { data, error } = await supabase
        .from("projects")
        .select()
        .eq("user_id", user?.id)
        .neq("status", "deleted");

      if (error) console.log(error);
      if (data) dispatch(setHostedProjects(data));
    }
  };

  const fetchProjects = async () => {
    const { data, error } = await supabase
      .from("project_info_with_total_applications")
      .select()
      .eq("user_id", user?.id);

    if (error) console.error("Error fetching projects:", error.message);

    if (data) {
      const filteredData = data.filter(
        (project) => project.status !== "deleted"
      );
      dispatch(setProjects(filteredData));
    }
  };

  const fetchApplications = async () => {
    const { data, error } = await supabase
      .from("project_applications_with_project_info")
      .select("*")
      .eq("user_id", user?.id);

    if (error) console.log(error);
    if (data) dispatch(setApplications(data));
  };

  return {
    toggleDrawer,
    handleFavorite,
    fetchFavorite,
    handleDeleteApplicants,
    handleRemoveApplication,
    handleProjectDelete,
    fetchProjectApplicants,
    fetchLikedProjects,
    fetchHostedProjects,
    fetchProjects,
    fetchApplications,
  };
}

export default useManageProjects;
