import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";
import { setIsPageLoading } from "../../store/slices/loadingSlice";
import { getAddressInfo } from "../../helpers/getAddressInfo";
import extractUTMParameters from "../../helpers/extractUTMParameters";
import { calculateUserGeneration } from "../../helpers/calculateUserGeneration";
import {
  setAgeRanges,
  setCollaboratorsTypes,
  setCommunityStatus,
  setCreatorTypes,
  setUser,
  setUserSkills,
} from "../../store/slices/userSlice";
import axios from "axios";
import { node_server } from "../../constants";

const useOnboarding = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const {
    data: {
      priorityCode,
      country,
      pronouns,
      postalCode,
      birthDate,
      selectedCollaboratorsTypes,
      creatorTypes,
      phoneNumber,
      countryCode,
    },
  } = useSelector((state) => state.registeration);

  const handleDataComplete = async () => {
    if (!user) alert("Cannot get your info");
    dispatch(setIsPageLoading(true));
    let shouldInsertStatus = true;

    const { data: statusData, error: statusError } = await axios.get(
      `${node_server}/api/user/status`,
      {
        withCredentials: true,
      }
    );

    if (statusError) console.log(statusError);
    if (statusData.length > 0) shouldInsertStatus = false;

    const addressData = await getAddressInfo(postalCode, country);
    const city = addressData?.city || "";
    const county = addressData?.county || "";
    const state = addressData?.state || "";

    const { utmSource, utmCampaign, utmContent } = extractUTMParameters(
      localStorage.getItem("starter-queries")
    );

    const { data: updatedUser, error: updatedUserError } = await axios.post(
      `${node_server}/api/user/update`,
      {
        priorityCode,
        country,
        generation: calculateUserGeneration(birthDate),
        postalCode,
        birthDate: moment(birthDate, "MM-DD-YYYY").format("YYYY-MM-DD"),
        signupCompleted: true,
        pronouns,
        lastRecordChange: new Date(),
        city,
        county,
        state,
        utmInfo: localStorage.getItem("starter-queries"),
        utmSource,
        utmCampaign,
        utmContent,
        mobile: countryCode + phoneNumber,
      },
      {
        withCredentials: true,
      }
    );

    if (updatedUserError) console.log(updatedUserError);

    localStorage.removeItem("starter-queries");

    if (!shouldInsertStatus) {
      if (updatedUser) dispatch(setUser(updatedUser[0]));
      navigate("/profile");
      return;
    }

    const { data, error } = await axios.post(
      `${node_server}/api/registration/user-data`,
      {
        creatorTypes,
        selectedCollaboratorsTypes,
        ageRanges: ["X", "X+", "Y", "Y+", "Z", "Z+"],
        status: ["Creator", "Incomplete"],
      },
      {
        withCredentials: true,
      }
    );

    if (error) console.log(error);
    if (data) {
      dispatch(setCreatorTypes(data.creatorTypes));
      dispatch(setCollaboratorsTypes(data.selectedCollaboratorsTypes));
      dispatch(setAgeRanges(data.ageRanges));
      dispatch(setCommunityStatus(data.status));
      dispatch(setUserSkills(data.skills));
    }

    TagManager.dataLayer({
      dataLayer: {
        event: "successful registration",
      },
    });

    if (updatedUser) dispatch(setUser(updatedUser[0]));
    navigate("/profile");
  };

  return { handleDataComplete };
};

export default useOnboarding;
