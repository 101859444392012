import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../../context/themeContext";
import { useDispatch, useSelector } from "react-redux";
import { updateBirthDate } from "../../../../../../store/slices/infoSlice";

const UnderAgeDialog = ({ altText }) => {
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const { isUnderAgeDialogOpen } = useSelector((state) => state.info.birthDate);

  const handleClick = () => {
    dispatch(updateBirthDate({ isUnderAgeDialogOpen: false }));
  };

  return (
    <Modal
      open={isUnderAgeDialogOpen}
      onClose={() => dispatch(updateBirthDate({ isUnderAgeDialogOpen: false }))}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "25px",
        alignSelf: "stretch",
        flex: "1 0 0",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "2.5rem 1.5rem",
          borderRadius: "8px",
          width: "330px",
          backgroundColor: theme.palette.lightgrey.lightgrey00,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "2.5rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "48px",
              alignItems: "flex-start",
              gap: "8px",
              borderRadius: "100px",
              background:
                "linear-gradient(160deg, #0F5FF7 9.58%, #9373FF 51.07%, #E81CFF 93.44%)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px 1.5px 20px 1.5px",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  leadingTrim: "both",
                  textEdge: "cap",
                  fontSize: "48px",
                  fontWeight: "700",
                  lineHeight: "48px",
                }}
              >
                13+
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              alignSelf: "stretch",
            }}
          >
            <Typography
              variant="body1"
              color={theme.palette.darkgrey.darkgrey400}
              sx={{
                textAlign: "center",
              }}
            >
              <span style={{ color: theme.palette.darkgrey.darkgrey600 }}>
                Oh shoot!{" "}
              </span>
              LYNX is only for creators 13 years old and over.
              {altText
                ? ` ${altText}`
                : `Please check back on your 13th birthday 🙂`}
            </Typography>
          </Box>
          <Button variant="contained" onClick={handleClick} fullWidth>
            Okay!
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UnderAgeDialog;
