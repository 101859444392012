import axios from "axios";
import { server_link } from "../constants";

async function generateFeeds(user) {
  let myReturnedRes;
  try {
    const response = await axios.post(`${server_link}api/generate-feeds`, {
      userId: user?.id,
    });
    myReturnedRes = response;
  } catch (error) {
    console.log(error);
  }

  return myReturnedRes?.data?.message;
}

export default generateFeeds;
