import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useTheme } from "../../../../../context/themeContext";
import drawerIconDark from "../../../../../assets/images/shoutOuts-icons/Shout Out Dark.svg";
import drawerIconLight from "../../../../../assets/images/shoutOuts-icons/Shout Out Light.svg";

const DrawrList = ({ toggleDrawer, handleShutClick }) => {
  const { theme, darkMode } = useTheme();

  return (
    <Box role="presentation" onClick={toggleDrawer(false)}>
      <Box
        sx={{
          display: "flex",
          alignSelf: "stretch",
          justifyContent: "center",
          padding: "8px 16px 24px",
        }}
      >
        <Box
          sx={{
            width: "54px",
            height: "4px",
            borderRadius: "32px",
            background: theme.palette.darkgrey.darkgrey500,
          }}
        ></Box>
      </Box>
      <List sx={{ p: "8px 0px 24px" }}>
        <ListItem sx={{ p: "8px 0px" }}>
          <ListItemButton
            onClick={handleShutClick}
            sx={{ paddingLeft: "24px" }}
          >
            <ListItemIcon sx={{ minWidth: "50px" }}>
              {darkMode ? (
                <img src={drawerIconDark} height="24px" alt="rock" />
              ) : (
                <img src={drawerIconLight} height="24px" alt="rock" />
              )}
            </ListItemIcon>
            <ListItemText
              sx={{ color: theme.palette.darkgrey.darkgrey500 }}
              primary="Shout Outs"
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

export default DrawrList;
