import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allCountries: [],
  infoBoxes: [],
  birthDate: {
    isUnderAgeDialogOpen: false,
  },
  invalid: {
    isInvalidOpen: false,
    message: "",
  },
  MLB: {
    isThanksOpen: false,
  },
  // ======================
  activeSkillChanged: false,
  addSkillState: false,
  mySkillsUpdated: false,
};

const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    setAllCountries: (state, action) => {
      state.allCountries = action.payload;
    },
    setInfoBoxes: (state, action) => {
      state.infoBoxes = action.payload;
    },
    updateBirthDate: (state, action) => {
      state.birthDate = { ...state.birthDate, ...action.payload };
    },
    updateInvalid: (state, action) => {
      state.invalid = { ...state.invalid, ...action.payload };
    },
    updateMLB: (state, action) => {
      state.MLB = { ...state.MLB, ...action.payload };
    },
    // =====================================
    setActiveSkillChanged: (state, action) => {
      state.activeSkillChanged = action.payload;
    },
    setAddSkillState: (state, action) => {
      state.addSkillState = action.payload;
    },
    setMySkillsUpdated: (state, action) => {
      state.mySkillsUpdated = action.payload;
    },
  },
});

export const {
  setAllCountries,
  setInfoBoxes,
  updateBirthDate,
  updateInvalid,
  updateMLB,
  // ====================
  setActiveSkillChanged,
  setAddSkillState,
  setMySkillsUpdated,
} = infoSlice.actions;
export default infoSlice.reducer;
