import { useTheme } from "../../../context/themeContext";
import styles from "./style.module.css";

function Loading() {
  const { theme } = useTheme();
  return (
    <div
      className={styles.container}
      style={{
        backgroundColor: theme.palette.lightgrey.lightgrey00,
      }}
    >
      <div className={styles.loadingDots}>
        <div className={styles.dot} id={styles.dot1}></div>
        <div className={styles.dot} id={styles.dot2}></div>
        <div className={styles.dot} id={styles.dot3}></div>
      </div>
    </div>
  );
}

export default Loading;
