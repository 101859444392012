import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openDeleteConfirmation: false,
  userScores: [],
  score: "",
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setOpenDeleteConfirmation: (state, action) => {
      state.openDeleteConfirmation = action.payload;
    },
    setUserScores: (state, action) => {
      state.userScores = action.payload;
    },
    setScore: (state, action) => {
      state.score = action.payload;
    },
  },
});

export const { setOpenDeleteConfirmation, setUserScores, setScore } =
  settingsSlice.actions;
export default settingsSlice.reducer;
