import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageLoading: false,
  buttonLoading: false,
};

const loadingSlice = createSlice({
  name: "isLoading",
  initialState,
  reducers: {
    setIsPageLoading(state, action) {
      state.pageLoading = action.payload;
    },
    setIsButtonLoading(state, action) {
      state.buttonLoading = action.payload;
    },
  },
});

export const { setIsPageLoading, setIsButtonLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
