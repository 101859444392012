import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setInfoBoxes } from "../store/slices/infoSlice";
import axios from "axios";
import { node_server } from "../constants";

const useInfoBoxs = () => {
  const dispatch = useDispatch();
  const { infoBoxes } = useSelector((state) => state.info);

  const fetchInfoBoxes = async () => {
    const { data, error } = await axios.get(`${node_server}/api/info-boxes`, {
      withCredentials: true,
    });

    if (error) console.log(error);
    if (data) dispatch(setInfoBoxes(data));
  };

  useEffect(() => {
    if (infoBoxes.length === 0) fetchInfoBoxes();
  }, []);

  return infoBoxes;
};

export default useInfoBoxs;
