import UseLocalStorageState from "./useLocalStorageState";

export default function useSkillsBar() {
  const [activeSkill, setActiveSkill] = UseLocalStorageState(
    "active-skill",
    null
  );

  return { activeSkill, setActiveSkill };
}
