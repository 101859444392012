import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";

const ProfileName = () => {
  const navigate = useNavigate();

  return (
    <Box className={styles.name}>
      <Typography
        variant="h6"
        sx={{
          color: "#fff",
          fontWeight: "700",
        }}
      >
        My Creator Card
      </Typography>
      <Chip
        label={"Make LYNX Better"}
        onClick={() => navigate("/make-lynx-better")}
        className={styles.name__button}
        sx={{
          textTransform: "initial",
          color: "#5C595C",
          backgroundColor: "#FFF",
        }}
      />
    </Box>
  );
};

export default ProfileName;
