import { Box } from "@mui/material";
import React from "react";
import styles from "./style.module.css";
import Logo from "../components/logo";
import CreatorType from "../components/creator-type";
import MLBThanks from "./MLBThanks";
import { useTheme } from "../../../../context/themeContext";
import useProtectedAuth from "../../../../hooks/useProtectedAuth";

const MakeLynxBetter = () => {
  useProtectedAuth();
  const { theme } = useTheme();

  return (
    <Box
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey500,
      }}
    >
      <MLBThanks />
      <Box className={styles.container}>
        <Box className={styles.arrow}></Box>
        <Logo />
        <CreatorType />
      </Box>
    </Box>
  );
};

export default MakeLynxBetter;
