import axios from "axios";
import { node_server } from "../constants";

export const uploadImage = async (file, fileName) => {
  try {
    const { data, error } = await axios.post(
      `${node_server}/api/images/presigned-url`,
      { fileName: fileName, fileType: file.type },
      {
        withCredentials: true,
      }
    );

    if (error) console.log(error);
    if (data) {
      const presignedUrl = data.signedUrl;
      const options = {
        headers: {
          "Content-Type": file.type,
        },
      };

      await axios.put(presignedUrl, file, options);
      const parsedUrl = new URL(presignedUrl);

      // Get the base URL without query parameters
      const baseUrl = `${parsedUrl.protocol}//${parsedUrl.host}${parsedUrl.pathname}`;

      return baseUrl;
    }
  } catch (err) {
    console.log(err);
  }
};
