import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projects: [],
  discoverItems: [],
  projectItems: [],
  favorites: [],
  projectFavorites: [],
  page: 1,
  hasMore: true,
  data: [],
  removing: false,
  isLoading: false,
};

const discoverSlice = createSlice({
  name: "discover",
  initialState,
  reducers: {
    setProjects(state, action) {
      state.projects = action.payload;
    },
    setDiscoverItems: (state, action) => {
      state.discoverItems = action.payload;
    },
    setProjectItems: (state, action) => {
      state.projectItems = action.payload;
    },
    setFavorites: (state, action) => {
      state.favorites = action.payload;
    },
    setProjectFavorites: (state, action) => {
      state.projectFavorites = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setHasMore: (state, action) => {
      state.hasMore = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setRemoving: (state, action) => {
      state.removing = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setProjects,
  setDiscoverItems,
  setProjectItems,
  setFavorites,
  setProjectFavorites,
  setPage,
  setHasMore,
  setData,
  setRemoving,
  setIsLoading,
} = discoverSlice.actions;

export default discoverSlice.reducer;
