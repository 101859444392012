import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import styles from "./styles.module.css";
import { useTheme } from "../../../../../context/themeContext";
import { useSelector } from "react-redux";
import LoadingButton from "../../../../../components/loading/button";

const Status = () => {
  const { theme, darkMode } = useTheme();
  const status = useSelector((state) => state.user.communityStatus);

  return (
    <Box
      className={styles.community}
      sx={{ bgcolor: theme.palette.lightgrey.lightgrey00, width: "100%" }}
    >
      <Typography
        variant="body1"
        sx={{
          color: theme.palette.darkgrey.darkgrey600,
          fontWeight: 700,
          fontSize: "18px",
        }}
      >
        Tags
      </Typography>
      <Box className={styles.tags}>
        {status.length === 0 ? (
          <LoadingButton />
        ) : (
          status.map((item) => {
            return (
              <Chip
                label={item.status}
                key={item.status}
                className={styles.tag}
                sx={{
                  background:
                    item.status === "Incomplete" &&
                    !darkMode &&
                    theme.palette.danger.red100,
                  color:
                    item.status === "Incomplete"
                      ? darkMode
                        ? theme.palette.danger.red200
                        : theme.palette.danger.red500
                      : darkMode
                      ? "#E2DFDF"
                      : "#5C595C",
                }}
              />
            );
          })
        )}
      </Box>
    </Box>
  );
};
export default Status;
