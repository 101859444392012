import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  copiedData: {},
};

const aboutEditSlice = createSlice({
  name: "aboutEdit",
  initialState,
  reducers: {
    setAboutEditData: (state, action) => {
      state.data = action.payload;
    },
    updateAboutEditData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    setAboutEditCopiedData: (state, action) => {
      state.copiedData = action.payload;
    },
    updateAboutEditCopiedData: (state, action) => {
      state.copiedData = { ...state.copiedData, ...action.payload };
    },
    resetAboutEditState: () => initialState,
  },
});

export const {
  setAboutEditData,
  updateAboutEditData,
  setAboutEditCopiedData,
  updateAboutEditCopiedData,
  resetAboutEditState,
} = aboutEditSlice.actions;

export default aboutEditSlice.reducer;
