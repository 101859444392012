import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { node_server, server_link } from "../constants";
import { mapScoreToDescription } from "../helpers/mapScoreToDescription";
import { setScore, setUserScores } from "../store/slices/profile/settingsSlice";
import { setUser } from "../store/slices/userSlice";

function useSettings() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const { userScores } = useSelector((state) => state.settings);

  const recalculateScore = async () => {
    try {
      if (user?.id) {
        const { data } = await axios.get(
          `${server_link}api/users_scores?userId=${user.id}`
        );
        if (data.message === "User Score Updated") {
          const finalScore = mapScoreToDescription(data.totalScore).finalScore;
          dispatch(setScore(finalScore));

          const { data: updatedUser, error } = await axios.post(
            `${node_server}/api/user/update`,
            { score: data.totalScore },
            { withCredentials: true }
          );

          if (error) console.error(error);
          if (data) dispatch(setUser(updatedUser));
        }
      }
    } catch {
      console.error();
    }
  };

  const fetchUserScores = async () => {
    const { data, error } = await axios.get(`${node_server}/api/user-scores`, {
      withCredentials: true,
    });

    if (error) console.log(error);
    if (data) dispatch(setUserScores(data[0]));
  };

  useEffect(() => {
    if (!user) return;
    const userScore = mapScoreToDescription(user?.score);
    dispatch(setScore(userScore.finalScore));
    if (userScores?.length === 0) fetchUserScores();
  }, [user]);

  return {
    recalculateScore,
    fetchUserScores,
  };
}

export default useSettings;
