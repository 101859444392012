import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchKeyword: "",
  searchType: "singleSearch",
  category: "",
  selectedSkill: null,
  selectedSpecialty: null,
  selectedCountry: null,
  selectedState: null,
  selectedEditorChoice: false,
  selectedProsOnly: false,
  selectedAgeRanges: [],
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchKeyword: (state, action) => {
      state.searchKeyword = action.payload;
    },
    setSearchType: (state, action) => {
      state.searchType = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setSelectedSkill: (state, action) => {
      state.selectedSkill = action.payload;
    },
    setSelectedSpecialty: (state, action) => {
      state.selectedSpecialty = action.payload;
    },
    setSelectedCountry: (state, action) => {
      state.selectedCountry = action.payload;
    },
    setSelectedState: (state, action) => {
      state.selectedState = action.payload;
    },
    setSelectedEditorChoice: (state, action) => {
      state.selectedEditorChoice = action.payload;
    },
    setSelectedProsOnly: (state, action) => {
      state.selectedProsOnly = action.payload;
    },
    setSelectedAgeRanges: (state, action) => {
      state.selectedAgeRanges = action.payload;
    },
  },
});

export const {
  setSearchKeyword,
  setSearchType,
  setCategory,
  setSelectedSkill,
  setSelectedSpecialty,
  setSelectedCountry,
  setSelectedState,
  setSelectedEditorChoice,
  setSelectedProsOnly,
  setSelectedAgeRanges,
} = searchSlice.actions;

export default searchSlice.reducer;
