import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import styles from "./style.module.css";
import { useTheme } from "../../../../context/themeContext";
import { useDispatch, useSelector } from "react-redux";
import { updateRegistrationData } from "../../../../store/slices/registerationSlice";
import SubmitButton from "../SubmitButton";
import LoadingButton from "../../../loading/button";
import axios from "axios";
import { node_server } from "../../../../constants";

const Step4 = ({ setIsRegisterationFinished }) => {
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const {
    data: { collaboratorsTypes, selectedCollaboratorsTypes },
  } = useSelector((state) => state.registeration);

  const handleFinishClick = () => {
    setIsRegisterationFinished(true);
    dispatch(updateRegistrationData({ completedInfo: true }));
  };

  const handleToggle = (value) => () => {
    const itemIndex = selectedCollaboratorsTypes.findIndex(
      (item) => item.category === value.category
    );
    if (itemIndex === -1) {
      return dispatch(
        updateRegistrationData({
          selectedCollaboratorsTypes: [...selectedCollaboratorsTypes, value],
        })
      );
    }
    // item exists remove it
    const filteredItems = selectedCollaboratorsTypes.filter(
      (item) => item.category !== value.category
    );
    dispatch(
      updateRegistrationData({ selectedCollaboratorsTypes: [...filteredItems] })
    );
  };

  useEffect(() => {
    const fetchCollaboratorsTypes = async () => {
      const response = await axios.get(
        `${node_server}/api/registration/collaborator-types`,
        {
          withCredentials: true,
        }
      );

      if (response.error) console.log(response.error);
      if (response.data) {
        dispatch(updateRegistrationData({ collaboratorsTypes: response.data }));
      }
    };

    if (collaboratorsTypes.length === 0) fetchCollaboratorsTypes();
  }, []);

  return (
    <Box className={styles.form}>
      <Box
        sx={{
          bgcolor: theme.palette.lightgrey.lightgrey400,
          p: "1.5rem",
        }}
      >
        <Typography
          variant="body1"
          color={theme.palette.darkgrey.darkgrey600}
          sx={{
            fontSize: "18px",
            fontWeight: "700",
          }}
        >
          LYNX is a collaboration platform for creators to discover other
          creators. Who would you like to collab with? (Choose as many as you
          like!)
        </Typography>
        <List
          sx={{
            width: "100%",
            mt: "24px",
            m: 0,
            color: theme.palette.darkgrey.darkgrey500,
          }}
        >
          {collaboratorsTypes.length === 0 ? (
            <LoadingButton />
          ) : (
            collaboratorsTypes.map((type) => {
              const labelId = `checkbox-list-label-${type.category}`;
              return (
                <ListItem
                  key={labelId}
                  disablePadding
                  sx={{
                    padding: 0,
                    "& .css-cveggr-MuiListItemIcon-root": {
                      minWidth: "auto",
                    },
                  }}
                >
                  <ListItemButton
                    role={undefined}
                    onClick={handleToggle(type)}
                    dense
                    sx={{
                      paddingLeft: "0px",
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={
                          selectedCollaboratorsTypes.findIndex(
                            (item) => item.category === type.category
                          ) !== -1
                        }
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={`${type.collaboratorDescription}`}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })
          )}
        </List>
      </Box>

      <SubmitButton clickNext={handleFinishClick} className={styles.next} />
    </Box>
  );
};

export default Step4;
