import React, { useState } from "react";
import { Box } from "@mui/material";
import Landing from "./landing";
import DeleteConfirmation from "./delete-account";
import useProtectedAuth from "../../hooks/useProtectedAuth";

const Settings = () => {
  useProtectedAuth();
  const [isDeleteConfirmationOpened, setIsDeleteConfirmationOpened] =
    useState(true);

  return (
    <Box>
      <Landing />
      <DeleteConfirmation
        isOpened={isDeleteConfirmationOpened}
        setIsOpend={setIsDeleteConfirmationOpened}
      />
    </Box>
  );
};
export default Settings;
