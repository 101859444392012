import { Box, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import UnderAgeDialog from "./components/underAge";
import SubmitButton from "../SubmitButton";
import styles from "./style.module.css";
import { useTheme } from "../../../../context/themeContext";
import Content from "./components/content";
import {
  updateBirthDate,
  updateInvalid,
} from "../../../../store/slices/infoSlice";
import Invalid from "../../../common/Invalid";

const Step3 = ({ clickNext }) => {
  const { theme } = useTheme();
  const dispatch = useDispatch();

  const {
    data: { birthDate },
  } = useSelector((state) => state.registeration);

  const handleSubmit = (e) => {
    e.preventDefault();
    const birth = Math.abs(moment(birthDate).diff(moment(), "year"));

    if (birthDate === "") {
      dispatch(
        updateInvalid({
          isInvalidOpen: true,
          message:
            "Invalid age range. Please enter the age with a valid range.",
        })
      );
      return;
    }

    if (birth < 13) {
      dispatch(updateBirthDate({ isUnderAgeDialogOpen: true }));
      return;
    }

    clickNext();
  };

  return (
    <Box className={styles.form}>
      <Invalid />
      <UnderAgeDialog />
      <Box
        sx={{
          bgcolor: theme.palette.lightgrey.lightgrey400,
          p: "1.5rem",
        }}
      >
        <Typography
          variant="body1"
          color={theme.palette.darkgrey.darkgrey600}
          sx={{
            fontSize: "18px",
            fontWeight: "700",
          }}
        >
          Give us a few basic details about yourself:
        </Typography>

        <Content />
      </Box>

      <SubmitButton clickNext={handleSubmit} />
    </Box>
  );
};

export default Step3;
