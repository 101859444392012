import { Box } from "@mui/material";
import styles from "./style.module.css";
import BasicTabs from "./tabs";
import { useTheme } from "../../../context/themeContext";
import useProtectedAuth from "../../../hooks/useProtectedAuth";

const ProjectManage = () => {
  useProtectedAuth();
  const { theme } = useTheme();

  return (
    <Box
      className={styles.projectManage}
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey500,
      }}
    >
      <BasicTabs />
    </Box>
  );
};

export default ProjectManage;
