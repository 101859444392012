import React from "react";
import { Box, Container } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "./style.module.css";
import img1 from "../../../../assets/images/login/start-page-image1.jpg";
import img2 from "../../../../assets/images/login/start-page-image2.jpg";
import img3 from "../../../../assets/images/login/start-page-image3.jpg";
import img4 from "../../../../assets/images/login/start-page-image4.jpg";
import img5 from "../../../../assets/images/login/start-page-image5.jpg";

const CarouselComponent = () => {
  const items = [
    {
      bgImg: img1,
    },
    {
      bgImg: img2,
    },
    {
      bgImg: img3,
    },
    {
      bgImg: img4,
    },
    {
      bgImg: img5,
    },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Container className={styles.container}>
      <Box
        sx={{
          "& .react-multi-carousel-list": {
            overflow: "initial",
          },
          "& .react-multi-carousel-item": {
            transform: "scale(0.9)",
          },
          "& .react-multi-carousel-item--active": {
            transform: "scale(1)",
          },
          "& .custom-dot-list-style": {
            bottom: "-40px",
            gap: "12px",
          },
          ".react-multi-carousel-dot button": {
            borderRadius: "0",
            background: "rgba(247, 247, 247, 0.40)",
            border: "none",
            width: "8px",
            height: "8px",
          },
          "& .react-multi-carousel-dot--active button": {
            transform: "scale(1.3)",
            background: "#F7F7F7",
          },
        }}
      >
        <Carousel
          responsive={responsive}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          showDots={true}
          ssr={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          customTransition="all .5"
          transitionDuration={500}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {items.map((item, index) => (
            <Box className={styles.card} key={index}>
              <img
                width={300}
                height={400}
                src={item.bgImg}
                style={{
                  margin: 0,
                  padding: 0,
                  boxSizing: "border-box",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt=""
              />
            </Box>
          ))}
        </Carousel>
      </Box>
    </Container>
  );
};

export default CarouselComponent;
