import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  category: "",
  skill: "",
  speciality: "",
  isPrimary: false,
  description: "",
  portfolioLink: "",
  ageStarted: "",
  level: "",
  acceptMarket: false,
};

const editSkillSlice = createSlice({
  name: "editSkill",
  initialState,
  reducers: {
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setSkill: (state, action) => {
      state.skill = action.payload;
    },
    setSpeciality: (state, action) => {
      state.speciality = action.payload;
    },
    setIsPrimary: (state, action) => {
      state.isPrimary = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setPortfolioLink: (state, action) => {
      state.portfolioLink = action.payload;
    },
    setAgeStarted: (state, action) => {
      state.ageStarted = action.payload;
    },
    setLevel: (state, action) => {
      state.level = action.payload;
    },
    setAcceptMarket: (state, action) => {
      state.acceptMarket = action.payload;
    },
    clearSkillEditState: (state) => {
      state.category = "";
      state.skill = "";
      state.speciality = "";
      state.isPrimary = false;
      state.description = "";
      state.portfolioLink = "";
      state.ageStarted = "";
      state.level = "";
      state.acceptMarket = false;
    },
  },
});

export const {
  setCategory,
  setSkill,
  setSpeciality,
  setIsPrimary,
  setDescription,
  setPortfolioLink,
  setAgeStarted,
  setLevel,
  setAcceptMarket,
  clearSkillEditState,
} = editSkillSlice.actions;
export default editSkillSlice.reducer;
