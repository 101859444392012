import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useTheme } from "../../../../../context/themeContext";

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const ProfileTabs = ({ data, isIndecatorHide, setHideIndcator }) => {
  const { theme } = useTheme();

  const handleChange = (event, newValue) => {
    data.setValue(newValue);
    setHideIndcator(false);
  };

  const clearSearchParams = () => {
    // Create a new URL object based on the current URL
    const url = new URL(window.location.href);

    // Clear all search parameters
    url.search = "";

    // Use history.replaceState to update the URL without reloading the page
    window.history.replaceState({}, document.title, url);
  };

  return (
    <React.Fragment>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={data.value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              width: "100%",
              "& .MuiTab-root": {
                color: "#fff",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "rgba(0,0,0,.1)",
                  color: "#000",
                  transition: ".3s ease-in-out",
                },
                "&.Mui-selected": {
                  color: theme.palette.tertiary.golden300,
                  "&:hover": {
                    backgroundColor: "inherit",
                  },
                },
              },
              "& .MuiTabs-indicator": {
                backgroundColor: theme.palette.tertiary.golden300,
                height: isIndecatorHide ? 0 : "2px",
              },
            }}
          >
            <Tab
              onClick={clearSearchParams}
              label="about me"
              {...a11yProps(0)}
            />
            <Tab
              onClick={clearSearchParams}
              label="my skills"
              {...a11yProps(1)}
            />
            <Tab
              onClick={clearSearchParams}
              label="projects"
              {...a11yProps(2)}
            />
            <Tab {...a11yProps(3)} disabled sx={{ minWidth: "0" }} />
          </Tabs>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ProfileTabs;
