const protocol = window.location.protocol;
const domain = window.location.hostname;
const port = window.location.port;
let defaultProfilePrefix;
let defaultProjectPrefix;
if (port) {
  defaultProfilePrefix = "/creators/";
  defaultProjectPrefix = "/projects/";
} else {
  defaultProfilePrefix = protocol + "/" + domain + "/creators/";
  defaultProjectPrefix = protocol + "/" + domain + "/projects/";
}

export { defaultProfilePrefix, defaultProjectPrefix };

export const defaultSkillImage =
  "https://lynx-react-images.s3.us-east-2.amazonaws.com/general/default-skill-image.png";

export const defaultProfileImageSquare =
  "https://lynx-react-images.s3.us-east-2.amazonaws.com/profile-picture-user-177-v-1717155053408";
export const defaultProfileImageCircl =
  "https://lynx-react-images.s3.us-east-2.amazonaws.com/profile-picture-user-177-v-1717155164030";
// export const defaultProfileImageFave =   ""

export const creatorDefaultImages = {
  "Performance & Modeling":
    "https://lynx-react-images.s3.us-east-2.amazonaws.com/retoolUploads/retool-uploadsDefault_Performance.png",
  "Street, Skate & Surf":
    "https://lynx-react-images.s3.us-east-2.amazonaws.com/retoolUploads/retool-uploadsDefault_Street%20Arts.png",
  "TV & Film Making":
    "https://lynx-react-images.s3.us-east-2.amazonaws.com/retoolUploads/retool-uploadsDefault_Film%20TV.png",
  Music:
    "https://lynx-react-images.s3.us-east-2.amazonaws.com/retoolUploads/retool-uploadsDefault_Music.png",
  "Fine Arts":
    "https://lynx-react-images.s3.us-east-2.amazonaws.com/retoolUploads/retool-uploadsDefault_Fine%20Arts.png",
  Fashion:
    "https://lynx-react-images.s3.us-east-2.amazonaws.com/retoolUploads/retool-uploadsDefault_Fashion.png",
  "Architecture & Space Design":
    "https://lynx-react-images.s3.us-east-2.amazonaws.com/retoolUploads/retool-uploadsDefault_Architecture_Space_Planning.png",
  "Culinary Arts":
    "https://lynx-react-images.s3.us-east-2.amazonaws.com/retoolUploads/retool-uploadsDefault_Culinary%20Arts.png",
  "Digital Art":
    "https://lynx-react-images.s3.us-east-2.amazonaws.com/retoolUploads/retool-uploadsDefault_Digital%20Arts.png",
  "Beauty & Styling":
    "https://lynx-react-images.s3.us-east-2.amazonaws.com/retoolUploads/retool-uploadsDefault_Beauty%20Styling.png",
  "Marketing ":
    "https://lynx-react-images.s3.us-east-2.amazonaws.com/retoolUploads/retool-uploadsDefault_Marketing.png",
  Technology:
    "https://lynx-react-images.s3.us-east-2.amazonaws.com/retoolUploads/retool-uploadsDefault_Technology.png",
  "Writing & Story Telling":
    "https://lynx-react-images.s3.us-east-2.amazonaws.com/retoolUploads/retool-uploadsDefault_Writing.png",
  "Product Design ":
    "https://lynx-react-images.s3.us-east-2.amazonaws.com/retoolUploads/retool-uploadsDefault_Product%20Design.png",
  Management:
    "https://lynx-react-images.s3.us-east-2.amazonaws.com/retoolUploads/retool-uploadsDefault_Management.png",
  "Events & Touring":
    "https://lynx-react-images.s3.us-east-2.amazonaws.com/retoolUploads/retool-uploadsDefault_Events.png",
};

export const server_link = process.env.REACT_APP_LYNX_SERVER;
export const node_server = process.env.REACT_APP_NODE_SERVER;

export const CommunityGuidlines_Url =
  "https://uploads-ssl.webflow.com/651750892fc953e528ca7b72/65a28b6d7c89e05bfa45b376_LYNX%20Community%20Guidelines.pdf";

export const Privacy_Url =
  "https://uploads-ssl.webflow.com/651750892fc953e528ca7b72/65a28b95390d57d916ca0b80_LYNX%20Privacy%20Policy.pdf";

export const Terms_Url =
  "https://uploads-ssl.webflow.com/651750892fc953e528ca7b72/65a28be0d84d81bc4859e400_LYNX%20Terms%20%26%20Conditions.pdf";
