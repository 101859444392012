import React from "react";
import "cropperjs/dist/cropper.min.css";
import { Box } from "@mui/material";
import styles from "./style.module.css";
import ImageCropper from "./components/cropper";
import { uploadImage } from "../../../../../../helpers/uploadImageToS3";
import imageCompression from "browser-image-compression";
import checkProfileComplete from "../../../../../../helpers/checkProfileComplete";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileImageData } from "../../../../../../store/slices/profile/profileImageSlice";
import { setIsButtonLoading } from "../../../../../../store/slices/loadingSlice";
import {
  setCommunityStatus as setUserStatus,
  setUser,
} from "../../../../../../store/slices/userSlice";
import axios from "axios";
import { node_server } from "../../../../../../constants";

const EditProfileImage = () => {
  const dispatch = useDispatch();
  const {
    value: user,
    communityStatus: userStatus,
    userSkills,
    collaboratorsTypes,
  } = useSelector((state) => state.user);
  const {
    data: { selectedProfileImage },
  } = useSelector((state) => state.profileImage);

  const handleCrop = async (croppedImage) => {
    try {
      // Convert the Uint8Array to a Blob
      const blob = new Blob([croppedImage], { type: "image/png" });
      const originalBlob = blob;

      // Compress the image using browser-image-compression library
      const options = {
        maxSizeMB: 1, // Set the maximum size of the compressed image to 0.5 MB
        maxWidthOrHeight: 800, // Set the maximum width or height of the compressed image
        useWebWorker: true, // Use a web worker for faster compression
        quality: 1, // Adjust the quality parameter (0.7 is a good starting point)
      };

      const compressedBlob = await imageCompression(originalBlob, options);

      const blobURL = await uploadImage(
        compressedBlob,
        "profile-picture-user-" + user.id + "-v-" + new Date().getTime()
      );

      dispatch(
        updateProfileImageData({
          croppedProfileImage: blobURL,
          isProfileImageModalOpen: false,
          // selectedProfileImage: null,
        })
      );

      const { data, error } = await axios.post(
        `${node_server}/api/user/update`,
        {
          profileImage: blobURL,
        },
        {
          withCredentials: true,
        }
      );

      if (error) console.error(error);
      if (data) dispatch(setUser(data));

      // handle original image upload
      if (selectedProfileImage !== user?.originalImage) {
        const response = await fetch(selectedProfileImage);
        const blobData = await response.blob();

        // Compress the image using browser-image-compression library
        const options = {
          maxSizeMB: 1, // Set the maximum size of the compressed image
          maxWidthOrHeight: 800, // Set the maximum width or height of the compressed image
          useWebWorker: true, // Use a web worker for faster compression
          quality: 1,
        };

        const compressedBlob = await imageCompression(blobData, options);

        const originalImageUrl = await uploadImage(
          compressedBlob,
          "original-image-user-" + user.id + "-v-" + new Date().getTime()
        );

        const { data, error } = await axios.post(
          `${node_server}/api/user/update`,
          {
            originalImage: originalImageUrl,
          },
          {
            withCredentials: true,
          }
        );

        if (error) console.error(error);
        if (data) {
          dispatch(setUser(data));
        }

        const userState = {
          user,
          setUser: (value) => dispatch(setUser(value)),
        };
        const statusState = {
          status: userStatus,
          setStatus: (value) => dispatch(setUserStatus(value)),
        };

        await checkProfileComplete(
          userState,
          statusState,
          userSkills,
          collaboratorsTypes
        );
      }

      dispatch(
        updateProfileImageData({
          selectedProfileImage: null,
        })
      );

      dispatch(setIsButtonLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setIsButtonLoading(false));
    }
  };

  const handleCancel = () => {
    dispatch(
      updateProfileImageData({
        croppedProfileImage: null,
        selectedProfileImage: null,
      })
    );
  };

  return (
    <Box className={styles.parent}>
      <Box className={styles.container}>
        <ImageCropper onCrop={handleCrop} onCancel={handleCancel} />
      </Box>
    </Box>
  );
};

export default EditProfileImage;
