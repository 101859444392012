import React, { useEffect } from "react";
import { Box } from "@mui/material";
import styles from "./style.module.css";
import { AddPhotoAlternateOutlined, EditOutlined } from "@mui/icons-material";
import { useTheme } from "../../../../../context/themeContext";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileImageData } from "../../../../../store/slices/profile/profileImageSlice";
import useProfileComplete from "../../../../../hooks/useProfileComplete";
import { updateProfileCompleteData } from "../../../../../store/slices/profile/profileCompleteSlice";

const ProfileImage = () => {
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  let profileImage = user?.profileImage;
  useEffect(() => {
    profileImage = user?.profileImage;
  }, [user]);

  useProfileComplete();
  const {
    data: { imageAdded },
  } = useSelector((state) => state.profileComplete);

  const openModal = () => {
    dispatch(
      updateProfileImageData({
        isProfileImageModalOpen: true,
        selectedProfileImage: user?.originalImage,
      })
    );
  };

  const handleProfileImageChange = () => {
    if (profileImage && !imageAdded) {
      dispatch(updateProfileCompleteData({ imageAdded: true }));
    }
    if (!profileImage && imageAdded) {
      dispatch(updateProfileCompleteData({ imageAdded: false }));
    }
  };

  useEffect(() => {
    handleProfileImageChange();
  }, [profileImage]);

  return (
    <React.Fragment>
      {profileImage === null && (
        <Box className={styles.image} onClick={openModal}>
          <AddPhotoAlternateOutlined
            sx={{
              width: "40px",
              height: "40px",
              flexShrink: "0",
              color: theme.palette.darkgrey.darkgrey400,
            }}
          />
        </Box>
      )}
      {profileImage && (
        <Box
          className={styles.image}
          sx={{
            backgroundImage: `url(${profileImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        >
          <Box className={styles.Cameraupload} onClick={openModal}>
            <EditOutlined
              fontSize="16px"
              sx={{ color: theme.palette.darkgrey.darkgrey400 }}
            />
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default ProfileImage;
