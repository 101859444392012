import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from "../SubmitButton";
import styles from "./style.module.css";
import { useTheme } from "../../../../context/themeContext";
import { updateRegistrationData } from "../../../../store/slices/registerationSlice";
import LoadingButton from "../../../loading/button";
import axios from "axios";
import { node_server } from "../../../../constants";

const Step2 = ({ clickNext }) => {
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const {
    data: { creatorTypes, collaboratorsTypes },
  } = useSelector((state) => state.registeration);

  const handleToggle = (value) => () => {
    const itemIndex = creatorTypes.findIndex(
      (item) => item.category === value.category
    );
    if (itemIndex === -1) {
      if (creatorTypes.length === 3) return;
      return dispatch(
        updateRegistrationData({ creatorTypes: [...creatorTypes, value] })
      );
    }
    // item exists remove it
    const filteredItems = creatorTypes.filter(
      (item) => item.category !== value.category
    );
    dispatch(updateRegistrationData({ creatorTypes: [...filteredItems] }));
  };

  const handleCheck = (type) => {
    return (
      creatorTypes.findIndex((item) => item.category === type.category) !== -1
    );
  };

  useEffect(() => {
    const fetchCollaboratorsTypes = async () => {
      const response = await axios.get(
        `${node_server}/api/registration/collaborator-types`,
        {
          withCredentials: true,
        }
      );

      if (response.error) console.log(response.error);
      if (response.data) {
        dispatch(
          updateRegistrationData({
            collaboratorsTypes: response.data,
          })
        );
      }
    };

    if (collaboratorsTypes.length === 0) fetchCollaboratorsTypes();
  }, []);

  return (
    <Box className={styles.form}>
      <Box
        sx={{
          bgcolor: theme.palette.lightgrey.lightgrey400,
          p: "1.5rem",
        }}
      >
        <Typography
          variant="body1"
          color={theme.palette.darkgrey.darkgrey600}
          sx={{
            fontSize: "18px",
            fontWeight: "700",
          }}
        >
          Choose up to 3 creator types which best describe your skill sets:
        </Typography>
        <List
          sx={{
            width: "100%",
            mt: "24px",
            m: 0,
            color: theme.palette.darkgrey.darkgrey500,
          }}
        >
          {collaboratorsTypes.length === 0 ? (
            <LoadingButton />
          ) : (
            collaboratorsTypes.map((type) => {
              const labelId = `checkbox-list-label-${type.category}`;
              return (
                <ListItem
                  key={labelId}
                  disablePadding
                  sx={{
                    padding: 0,
                    "& .css-cveggr-MuiListItemIcon-root": {
                      minWidth: "auto",
                    },
                  }}
                >
                  <ListItemButton
                    role={undefined}
                    onClick={handleToggle(type)}
                    dense
                    sx={{
                      paddingLeft: "0px",
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={handleCheck(type)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={`${type.creatorDescription}`}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })
          )}
        </List>
      </Box>

      <SubmitButton clickNext={clickNext} />
    </Box>
  );
};

export default Step2;
