import { useTheme } from "../../../../../../context/themeContext";
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateRegistrationData } from "../../../../../../store/slices/registerationSlice";
import LoadingButton from "../../../../../../components/loading/button";
import {
  updateAboutEditCopiedData,
  updateAboutEditData,
} from "../../../../../../store/slices/profile/aboutEditSlice";
import axios from "axios";
import { node_server } from "../../../../../../constants";

const CollaborateWith = () => {
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const {
    data: { collaboratorsTypes },
  } = useSelector((state) => state.registeration);
  const { value: user, collaboratorsTypes: selectedCollaboratorsTypes } =
    useSelector((state) => state.user);
  const {
    data: { localSelectedCollabs },
  } = useSelector((state) => state.aboutEdit);

  const handleDesiredCollaboratorToggle = (value) => () => {
    const itemIndex = localSelectedCollabs.findIndex(
      (item) => item.category === value.category
    );

    if (itemIndex === -1) {
      const el = selectedCollaboratorsTypes.find(
        (collab) => collab.type === value.creatorType
      );
      let item;
      if (el) {
        item = {
          id: el.id,
          type: value.creatorType,
          category: value.category,
          userId: user.id,
          displayName: value.collaboratorCategory,
        };
      } else {
        item = {
          type: value.creatorType,
          category: value.category,
          userId: user.id,
          displayName: value.collaboratorCategory,
        };
      }
      return dispatch(
        updateAboutEditData({
          localSelectedCollabs: [...localSelectedCollabs, item].sort(
            (a, b) => a.id - b.id
          ),
        })
      );
    }

    const filteredItems = localSelectedCollabs.filter(
      (item) => item.category !== value.category
    );
    dispatch(updateAboutEditData({ localSelectedCollabs: [...filteredItems] }));
  };

  const handleCheck = (type) => {
    return (
      localSelectedCollabs?.findIndex(
        (item) => item.category === type.category
      ) !== -1
    );
  };

  useEffect(() => {
    if (!user) return;
    dispatch(
      updateAboutEditData({ localSelectedCollabs: selectedCollaboratorsTypes })
    );
    dispatch(
      updateAboutEditCopiedData({
        localSelectedCollabs: selectedCollaboratorsTypes,
      })
    );
  }, [user, selectedCollaboratorsTypes]);

  useEffect(() => {
    if (!user) return;
    const fetchCollaboratorsTypes = async () => {
      const { data, error } = await axios.get(
        `${node_server}/api/registration/collaborator-types`,
        {
          withCredentials: true,
        }
      );
      if (error) console.log(error);
      if (data) dispatch(updateRegistrationData({ collaboratorsTypes: data }));
    };

    if (collaboratorsTypes.length === 0) fetchCollaboratorsTypes();
  }, [user]);

  return (
    <>
      <Typography
        variant="body1"
        color={theme.palette.darkgrey.darkgrey600}
        sx={{
          fontSize: "18px",
          fontWeight: "500",
        }}
      >
        I am looking to collaborate with:
      </Typography>

      <Box
        sx={{
          "& .css-cveggr-MuiListItemIcon-root": {
            minWidth: "5px",
          },
          "& .css-118ob9i-MuiButtonBase-root-MuiListItemButton-root": {
            p: "0px",
          },
        }}
      >
        <List
          sx={{
            width: "100%",
            mb: "24px",
            mt: "24px",
            m: 0,
            color: theme.palette.darkgrey.darkgrey500,
          }}
        >
          {collaboratorsTypes.length === 0 ? (
            <LoadingButton />
          ) : (
            collaboratorsTypes.map((type) => {
              const labelId = `checkbox-list-label-${type.category}`;
              return (
                <ListItem
                  key={type.category}
                  disablePadding
                  sx={{ padding: 0 }}
                >
                  <ListItemButton
                    role={undefined}
                    onClick={handleDesiredCollaboratorToggle(type)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={handleCheck(type)}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={`${type.collaboratorDescription}`}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })
          )}
        </List>
      </Box>
    </>
  );
};

export default CollaborateWith;
