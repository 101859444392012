function extractUTMParameters(urlString) {
  const params = {};
  const urlParams = new URLSearchParams(urlString);

  // Extract utmSource, utmCampaign, and utmContent parameters
  params.utmSource = urlParams.get("utm_source");
  params.utmCampaign = urlParams.get("utm_campaign");
  params.utmContent = urlParams.get("utm_content");

  return params;
}

export default extractUTMParameters;
