import { Box } from "@mui/material";
import styles from "./style.module.css";

function LoadingButton() {
  return (
    <Box className={styles.container}>
      <Box className={styles.loadingDots}>
        <Box className={styles.dot} id={styles.dot1}></Box>
        <Box className={styles.dot} id={styles.dot2}></Box>
        <Box className={styles.dot} id={styles.dot3}></Box>
      </Box>
    </Box>
  );
}

export default LoadingButton;
