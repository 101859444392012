export function mapScoreToDescription(score) {
  let description = "";
  let finalScore = "";
  if (score >= 210) {
    description = "Amazing!";
    finalScore = "A+";
  } else if (score >= 175 && score <= 209) {
    description = "Top Talent";
    finalScore = "A";
  } else if (score >= 140 && score <= 174) {
    description = "Great!";
    finalScore = "A-";
  } else if (score >= 100 && score <= 139) {
    description = "Very Good";
    finalScore = "B+";
  } else if (score >= 85 && score <= 99) {
    description = "Strong Start";
    finalScore = "B";
  } else if (score >= 75 && score <= 84) {
    description = "Above Average";
    finalScore = "B-";
  } else if (score >= 70 && score <= 74) {
    description = "Getting There";
    finalScore = "C+";
  } else if (score >= 65 && score <= 69) {
    description = "Average";
    finalScore = "C";
  } else if (score >= 60 && score <= 64) {
    description = "Below Average";
    finalScore = "C-";
  } else if (score >= 55 && score <= 59) {
    description = "Poor";
    finalScore = "D";
  } else if (score >= 0 && score <= 54) {
    description = "Failing!";
    finalScore = "F";
  } else {
    description = "Score out of range";
  }

  return {
    finalScore: finalScore,
    description: description,
  };
}
