import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Status from "./components/status";
import UserCollaborators from "./components/userCollaborators";
import UserInfo from "./components/userInfo";
import UserLocation from "./components/userLocation";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { node_server } from "../../../constants";
import {
  setAgeRanges,
  setCollaboratorsTypes,
  setCommunityStatus,
} from "../../../store/slices/userSlice";
import { sortArrayByAgeCategory } from "../../../helpers/sortArrays";

const AboutMe = () => {
  const {
    value: user,
    collaboratorsTypes: userCollaboratorsTypes,
    ageRanges: userAgeRanges,
    communityStatus: userStatus,
  } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) return;
    const fetchData = async () => {
      const { data, error } = await axios.get(
        `${node_server}/api/profile/about-me`,
        { withCredentials: true }
      );

      if (error) console.log(error);
      if (data) {
        dispatch(setCommunityStatus(data.status));
        dispatch(setCollaboratorsTypes(data.collaborators));
        const sortedArray = sortArrayByAgeCategory(data.ageRanges);
        dispatch(setAgeRanges(sortedArray));
      }
    };

    if (
      userCollaboratorsTypes?.length === 0 ||
      userAgeRanges?.length === 0 ||
      userStatus?.length === 0
    )
      fetchData();
  }, [user]);

  return (
    <Box
      sx={{
        display: "flex",
        padding: "16px 24px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "16px",
        alignSelf: "stretch",
      }}
    >
      <Status />
      <UserInfo />
      <UserLocation />
      <UserCollaborators />
      <Link
        to="/about-edit"
        underline="none"
        color="#fff"
        style={{ width: "100%" }}
      >
        <Button
          variant="contained"
          sx={{
            width: {
              xs: "100%",
            },
          }}
        >
          Edit
        </Button>
      </Link>
    </Box>
  );
};

export default AboutMe;
