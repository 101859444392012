import { useTheme } from "../../../../../../context/themeContext";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import useInfoBoxs from "../../../../../../hooks/useInfoBoxs";
import Info from "../../../../../../components/info";
import {
  updateAboutEditCopiedData,
  updateAboutEditData,
} from "../../../../../../store/slices/profile/aboutEditSlice";
import { sortArrayByAgeCategory } from "../../../../../../helpers/sortArrays";

const Gens = ["X", "X+", "Y", "Y+", "Z", "Z+"];

const AgeRanges = () => {
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const { value: user, ageRanges: selectedAgeRanges } = useSelector(
    (state) => state.user
  );
  const {
    data: { localSelectedAgeRanges },
  } = useSelector((state) => state.aboutEdit);

  const infoBoxes = useInfoBoxs();

  const handleAgeRangesChange = (range) => {
    const index = localSelectedAgeRanges.findIndex(
      (item) => item.ageCategory === range
    );

    if (index === -1) {
      const sortedArray = sortArrayByAgeCategory([
        ...localSelectedAgeRanges,
        { userId: user.id, ageCategory: range },
      ]);
      dispatch(
        updateAboutEditData({
          localSelectedAgeRanges: sortedArray,
        })
      );
    } else {
      const sortedArray = sortArrayByAgeCategory(
        localSelectedAgeRanges.filter((item) => item.ageCategory !== range)
      );
      dispatch(
        updateAboutEditData({
          localSelectedAgeRanges: sortedArray,
        })
      );
    }
  };

  const handleCheck = (gen) => {
    return (
      localSelectedAgeRanges?.findIndex((item) => item?.ageCategory === gen) !==
      -1
    );
  };

  useEffect(() => {
    if (!user) return;
    dispatch(
      updateAboutEditData({ localSelectedAgeRanges: selectedAgeRanges })
    );
    dispatch(
      updateAboutEditCopiedData({ localSelectedAgeRanges: selectedAgeRanges })
    );
  }, [user, selectedAgeRanges]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Typography
        variant="body1"
        color={theme.palette.darkgrey.darkgrey600}
        sx={{
          fontWeight: "500",
        }}
      >
        Age Range
      </Typography>
      <FormGroup
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          "& .css-ahj2mt-MuiTypography-root": {
            fontSize: "14px",
          },
        }}
      >
        {Gens.map((Gen, index) => (
          <Box
            key={Gen}
            className={styles.ageGen}
            sx={{
              "& .css-j204z7-MuiFormControlLabel-root": {
                marginRight: "0",
              },
              "& .css-onpov0-MuiButtonBase-root-MuiCheckbox-root": {
                padding: "0px 8px 0px 9px",
              },
            }}
          >
            <FormControlLabel
              control={<Checkbox size="small" />}
              defaultChecked={
                localSelectedAgeRanges?.findIndex((item) => item === Gen) === -1
                  ? false
                  : true
              }
              checked={handleCheck(Gen)}
              onChange={() => handleAgeRangesChange(Gen)}
              label={`Gen ${Gen}`}
              sx={{
                color: theme.palette.darkgrey.darkgrey500,
              }}
            />
            {infoBoxes.map((item, index) => {
              if (item.name === `Gen${Gen}`) {
                return (
                  <Info
                    heading={item.title}
                    content={item.content}
                    key={item.name}
                  />
                );
              }
            })}
          </Box>
        ))}
      </FormGroup>
    </Box>
  );
};

export default AgeRanges;
