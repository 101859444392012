import { useEffect, useState } from "react";
import supabase from "../supabase/client";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  setData,
  setDiscoverItems,
  setFavorites,
  setHasMore,
  setIsLoading,
  setPage,
  setProjectFavorites,
  setProjectItems,
  setProjects,
  setRemoving,
} from "../store/slices/profile/discoverSlice";

function useDiscover(noEffects = false) {
  const ITEMS_PER_PAGE = 5;

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const {
    favorites,
    data,
    discoverItems,
    projectItems,
    page,
    hasMore,
    isLoading,
  } = useSelector((state) => state.discover);

  const [bottomReached, setBottomReached] = useState(false);

  const deleteShoutout = async (client) => {
    try {
      const { error } = await supabase.from("user_shoutouts").delete().match({
        sender_id: client.user_id,
        receiver_id: client.matched_user_id,
      });

      if (error) {
        console.error("Error deleting shoutout:", error);
      } else {
        const filterdFav = favorites.filter(
          (item) => item.receiver_id !== client.matched_user_id
        );

        dispatch(setFavorites(filterdFav));
        dispatch(
          setData([
            ...data.map((item) =>
              item === client ? { ...item, shout_outed: false } : item
            ),
          ])
        );

        const { error } = await supabase
          .from("user_feed")
          .update({
            shout_outed: false,
          })
          .eq("user_id", user?.id)
          .eq("matched_user_id", client.matched_user_id);

        if (error) console.log(error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const insertShoutout = async (client) => {
    try {
      if (client.shout_outed === false) {
        const { error } = await supabase.from("user_shoutouts").insert({
          sender_id: client.user_id,
          receiver_id: client.matched_user_id,
        });

        if (error) {
          console.error(error);
        } else {
          dispatch(
            setData([
              ...data.map((item) =>
                item === client ? { ...item, shout_outed: true } : item
              ),
            ])
          );

          const { error } = await supabase
            .from("user_feed")
            .update({
              shout_outed: true,
            })
            .eq("user_id", user?.id)
            .eq("matched_user_id", client.matched_user_id);

          if (error) console.log(error);
        }
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  const handleCloseProject = async (project) => {
    if (project.key === "project") {
      // const filteredProjects = data.filter(
      //   (item) => item.project_id !== project.project_id
      // );
      // dispatch(setData(filteredProjects));

      // Remember to handle the combined feed behavior .. Good Bye :)
      const filtered = projectItems.map((item) => {
        if (item?.project_id === project?.project_id) {
          return null;
        } else {
          return item;
        }
      });
      dispatch(setProjectItems(filtered));

      const { error } = await supabase
        .from("project_feed")
        .delete()
        .eq("user_id", project.user_id)
        .eq("project_id", project.project_id);

      if (!error) {
        try {
          const { error: exError } = await supabase
            .from("excluded_projects")
            .insert({
              user_id: project.user_id,
              removed_project_id: project.project_id,
            });
          if (exError) console.log(exError);
        } catch (e) {
          console.error(e);
        }
      } else {
        console.error(error);
      }
    } else if (project.key === "user") {
      // const filteredProjects = data.filter(
      //   (item) => item.matched_user_id !== project.matched_user_id
      // );
      // dispatch(setData(filteredProjects));

      // Remember to handle the combined feed behavior .. Good Bye :)
      const filtered = discoverItems.map((item) => {
        if (item?.matched_user_id === project?.matched_user_id) {
          return null;
        } else {
          return item;
        }
      });
      dispatch(setDiscoverItems(filtered));

      const { error } = await supabase
        .from("user_feed")
        .delete()
        .eq("user_id", project.user_id)
        .eq("matched_user_id", project.matched_user_id);

      if (!error) {
        try {
          const { error: exError } = await supabase
            .from("excluded_users")
            .insert({
              user_id: project.user_id,
              removed_user_id: project.matched_user_id,
            });

          if (exError) console.log(exError);
        } catch (e) {
          console.error(e);
        }
      } else {
        console.error(error);
      }
    }
  };

  const handleSwipeLeft = async (client) => {
    // console.log("Swiped left on:", client);
    // Handle left swipe action (e.g., delete client)
    // if ( client.key === "user") {
    //     await insertShoutout(client);
    // }
  };

  const handleSwipeRight = (card) => {
    dispatch(setRemoving(card));
    setTimeout(() => {
      handleCloseProject(card);
      dispatch(setRemoving(null));
    }, 300);
  };

  const fetchDiscoverData = async (pag) => {
    try {
      dispatch(setIsLoading(true));
      const { data: discoverData, error: discoverError } = await supabase
        .from("user_feed")
        .select("*")
        .eq("user_id", user?.id)
        .range((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE - 1)
        .order("match_score", { ascending: false })
        .order("matched_user_id", { ascending: true });

      if (discoverError) console.log(discoverError);
      if (discoverData)
        dispatch(setDiscoverItems([...discoverItems, ...discoverData]));

      const { data: projectData, error: projectError } = await supabase
        .from("project_feed")
        .select("*")
        .eq("user_id", user?.id)
        .range((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE - 1)
        .order("match_score", { ascending: false })
        .order("project_id", { ascending: true });

      if (projectError) console.log(projectError);
      if (projectData)
        dispatch(setProjectItems([...projectItems, ...projectData]));

      dispatch(
        setHasMore(
          discoverData?.length === ITEMS_PER_PAGE ||
            projectData?.length === ITEMS_PER_PAGE
        )
      );
    } catch (error) {
      console.error("Unexpected error:", error);
    } finally {
      dispatch(setIsLoading(false));
      setBottomReached(false);
    }
  };

  const combineDiscoveryFeed = () => {
    if (isLoading) return;
    dispatch(setIsLoading(true));
    const combinedData = [];
    const maxLength = Math.max(discoverItems?.length, projectItems?.length);
    for (let i = 0; i < maxLength; i++) {
      if (i < discoverItems.length) {
        if (discoverItems[i] !== null) {
          combinedData.push({ ...discoverItems[i], key: "user" });
        }
      }
      if (i < projectItems.length) {
        if (projectItems[i] !== null) {
          combinedData.push({ ...projectItems[i], key: "project" });
        }
      }
    }

    dispatch(setData([...combinedData]));
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    if (noEffects) return;

    const fetchShoutOuts = async () => {
      const { data, error } = await supabase
        .from("user_shoutouts")
        .select()
        .eq("sender_id", user?.id);

      if (data?.length > 0) dispatch(setFavorites(data));
      if (error) console.log(error);
    };

    const fetchProjectFavorites = async () => {
      const { data, error } = await supabase
        .from("projects_favourite")
        .select()
        .eq("user_id", user?.id);
      if (error) console.log(error);
      if (data) dispatch(setProjectFavorites(data));
    };

    const fetchData = async () => {
      const { data, error } = await supabase
        .from("project_user_info")
        .select()
        .neq("status", "Under Review")
        .order("project_order", { ascending: true });

      if (error) console.error(error);
      if (data) dispatch(setProjects(data));
    };

    if (user) {
      fetchData();
      fetchShoutOuts();
      fetchProjectFavorites();
    }
  }, [user]);

  useEffect(() => {
    if (noEffects) return;

    if (user?.id) {
      dispatch(setData([]));
      dispatch(setHasMore(true));
      dispatch(setPage(1));
    }
  }, [user]);

  useEffect(() => {
    if (noEffects) return;
    fetchDiscoverData(page);
  }, [page]);

  useEffect(() => {
    combineDiscoveryFeed();
  }, [discoverItems, projectItems]);

  useEffect(() => {
    const handleScroll = () => {
      if (pathname === "/discover/results" && !isLoading && hasMore) {
        if (
          window.innerHeight + window.scrollY ===
          document.documentElement.scrollHeight
        ) {
          // setBottomReached(true);
          dispatch(setPage(page + 1));
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pathname, data]);

  return {
    deleteShoutout,
    insertShoutout,
    handleSwipeLeft,
    handleSwipeRight,
    fetchDiscoverData,
    handleCloseProject,
  };
}

export default useDiscover;
