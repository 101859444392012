import {
  ClickAwayListener,
  Fade,
  IconButton,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "../../context/themeContext";

const Info = ({ heading, content }) => {
  const { theme } = useTheme();
  const [open, setOpen] = React.useState(false);

  const InfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.lightgrey.lightgrey500,
      maxWidth: "260px",
      padding: "16px",
      boxShadow: theme.shadows[1],
    },
  }));

  const getContent = () => {
    switch (heading) {
      case "Gen X+":
        return "includes all creators born in 1964 or before.";
      case "Gen X":
        return "includes all creators born between 1965 and 1979.";
      case "Gen Y+":
        return "includes all creators born between 1980 and 1987.";
      case "Gen Y":
        return "includes all creators born between 1988 and 1995.";
      case "Gen Z+":
        return "includes all creators born between 1996 and 2004.";
      case "Gen Z":
        return "includes all creators born in 2005 or after.";
      default:
        return content;
    }
  };

  const contentText = getContent();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <InfoTooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 300 }}
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableTouchListener
          title={
            <React.Fragment>
              <Typography
                sx={{ display: heading === "" && "none" }}
                color={theme.palette.darkgrey.darkgrey600}
                variant="body1"
              >
                {heading ? heading : "Info tab"}
              </Typography>
              <Typography
                color={theme.palette.darkgrey.darkgrey300}
                variant="body2"
              >
                {contentText
                  ? contentText
                  : "supporting line text lorem ipsum dolar sit amet,sghsdgh"}
              </Typography>
            </React.Fragment>
          }
        >
          <IconButton
            onClick={handleTooltipOpen}
            aria-label="info"
            sx={{
              border: "1px solid ",
              borderColor: theme.palette.darkgrey.darkgrey500,
              bgcolor: theme.palette.darkgrey.darkgrey100,
              display: "flex",
              width: "20px",
              height: "20px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontWeight: "500",
                color: theme.palette.darkgrey.darkgrey500,
              }}
            >
              i
            </Typography>
          </IconButton>
        </InfoTooltip>
      </div>
    </ClickAwayListener>
  );
};

export default Info;
