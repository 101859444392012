import React, { useEffect } from "react";
import { useTheme } from "../../../context/themeContext";
import { Box, Button, Link, Typography } from "@mui/material";
import logo from "../../../assets/images/logos/Color=Gredient, Size=Large.svg";
import image from "../../../assets/images/checks/check-circle-broken.svg";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styles from "./style.module.css";
import Google from "../../../assets/images/login/Vector.svg";
import useLocalStorageState from "../../../hooks/useLocalStorageState";
import { node_server } from "../../../constants";

const Thanks = ({ onboardingDataComplete }) => {
  const { theme } = useTheme();
  const { pathname } = useLocation();

  const redirectUrl = localStorage.getItem("redirectUrl");

  const {
    data: {
      country,
      pronouns,
      postalCode,
      birthdate,
      selectedCollaboratorsTypes,
      creatorTypes,
      phoneNumber,
      countryCode,
    },
  } = useSelector((state) => state.registeration);

  const [reversedRegistrationData, setReversedRegistrationData] =
    useLocalStorageState("reversedRegistrationData", {});
  const [shouldRegisterFromReversed, setShouldRegisterFromReversed] =
    useLocalStorageState("shouldRegisterFromReversed", false);

  useEffect(() => {
    if (pathname === "/onboarding") return;
    setReversedRegistrationData({
      country,
      pronouns,
      postalCode,
      birthdate,
      selectedCollaboratorsTypes,
      creatorTypes,
      phoneNumber,
      countryCode,
    });
    setShouldRegisterFromReversed(true);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0px 24px",
        backgroundColor: theme.palette.lightgrey.lightgrey600,
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "40px 0px",
        }}
      >
        <img src={logo} width="234px" alt="LYNX Logo" />
      </Box>
      <Box
        sx={{
          bgcolor: theme.palette.lightgrey.lightgrey00,
          p: "40px 24px",
          borderRadius: "12px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              padding: "48px",
              alignItems: "flex-start",
              gap: "8px",
              borderRadius: "100px",
              background:
                "linear-gradient(160deg, #0F5FF7 9.58%, #9373FF 51.07%, #E81CFF 93.44%)",
              color: theme.palette.lightgrey.lightgrey00,
            }}
          >
            <img src={image} alt="" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              alignSelf: "stretch",
            }}
          >
            <Typography
              variant="h5"
              color={theme.palette.darkgrey.darkgrey700}
              sx={{
                textAlign: "center",
                fontWeight: "700",
                fontSize: "24px",
              }}
            >
              One more step!
            </Typography>
            <Typography
              variant="body1"
              color={theme.palette.darkgrey.darkgrey400}
              sx={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              {pathname === "/onboarding" ? (
                <>
                  Please review and accept our{" "}
                  <a
                    href="https://uploads-ssl.webflow.com/651750892fc953e528ca7b72/65a28be0d84d81bc4859e400_LYNX%20Terms%20%26%20Conditions.pdf"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: theme.palette.secondary.dark,
                      textDecoration: "none",
                    }}
                  >
                    Terms of Service
                  </a>
                </>
              ) : (
                "Use the sign-in below to access your account"
              )}
            </Typography>
          </Box>
          {pathname === "/onboarding" ? (
            <Button
              variant="contained"
              sx={{
                width: "269px",
              }}
              onClick={onboardingDataComplete}
            >
              Accept & Submit
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{
                p: 0,
              }}
            >
              <Link
                href={`${node_server}/auth/google${
                  redirectUrl ? `?redirect_url=${redirectUrl}` : ""
                }`}
                className={styles.link}
              >
                <img
                  style={{
                    padding: "10px",
                    backgroundColor: "#F5F2F6",
                    borderRadius: "4px",
                  }}
                  src={Google}
                  alt="google"
                />
                Sign in with Google
              </Link>
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Thanks;
