import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [
    {
      num: 1,
      selectedCategory: "",
      selectedSkill: "",
      selectedSpecialty: "",
    },
  ],
};

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    setRoles: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setRoles } = rolesSlice.actions;

export default rolesSlice.reducer;
