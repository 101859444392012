import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from "../SubmitButton";
import styles from "./style.module.css";
import { updateRegistrationData } from "../../../../store/slices/registerationSlice";
import { useTheme } from "../../../../context/themeContext";

const Step1 = ({ clickNext }) => {
  const { theme } = useTheme();
  const {
    data: { priorityCode },
  } = useSelector((state) => state.registeration);
  const dispatch = useDispatch();

  return (
    <div className={styles.form}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "2px",
          padding: "1.5rem",
          bgcolor: theme.palette.lightgrey.lightgrey400,
        }}
      >
        <TextField
          label="Enter Priority Code"
          name="priorityCode"
          value={priorityCode}
          onChange={(e) => {
            dispatch(updateRegistrationData({ priorityCode: e.target.value }));
          }}
          variant="outlined"
          className={styles.textField}
          fullWidth
          sx={{
            backgroundColor: theme.palette.lightgrey.lightgrey00,
          }}
        />
        <Typography variant="caption">
          No priority code? No worries! Skip to the next step
        </Typography>
      </Box>

      <SubmitButton clickNext={clickNext} />
    </div>
  );
};

export default Step1;
