import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import "./index.css";

import "react-image-crop/dist/ReactCrop.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { ThemeContextProvider } from "./context/themeContext";

const tagManagerArgs = {
  gtmId: "GTM-NNR2C7ZZ",
};

TagManager.initialize(tagManagerArgs);
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_NUMBER);

process.env.NODE_ENV === "production" &&
  Sentry.init({
    dsn: "https://5ea9f8865392b2a723c314617fbfec68@o4506362149076992.ingest.sentry.io/4506377289072640",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: window.location.origin,
          }}
        >
          <ThemeContextProvider>
            <App />
          </ThemeContextProvider>
        </Auth0Provider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
