import { Box, TextField } from "@mui/material";
import React, { useEffect, useRef } from "react";
import styles from "./style.module.css";
import uploadIcon from "../../../../../../../../../../assets/images/upload-icons/upload-01.svg";
import editIcon from "../../../../../../../../../../assets/images/edit-icons/edit-03.svg";
import deleteIcon from "../../../../../../../../../../assets/images/trash-icons/trash-01.svg";
import { useSelector } from "react-redux";
import { useTheme } from "../../../../../../../../../../context/themeContext";
import { defaultProfileImageSquare } from "../../../../../../../../../../constants";

const Content = ({ handleFileChange, handleEdit, handleDelete }) => {
  const { theme } = useTheme();
  const user = useSelector((state) => state.user.value);
  const {
    data: { selectedProfileImage },
  } = useSelector((state) => state.profileImage);

  const imageInput = useRef();

  let profileImage = user?.profileImage;
  useEffect(() => {
    profileImage = user?.profileImage;
  }, [user]);

  const style = {
    bgcolor: theme.palette.lightgrey.lightgrey500,
  };

  return (
    <Box sx={style} className={styles.upload}>
      <Box sx={style} className={styles.uploadPhoto}>
        <input
          type="file"
          accept="image/*"
          id="imageInput"
          style={{ display: "none" }}
          ref={imageInput}
          onChange={handleFileChange}
        />
        <TextField
          fullWidth
          value={"Choose photo from gallery"}
          onClick={() => imageInput.current.click()}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <label htmlFor="imageInput" style={{ cursor: "pointer" }}>
                <img src={uploadIcon} alt="upload" />
              </label>
            ),
            style: {
              backgroundColor: theme.palette.lightgrey.lightgrey00,
              color: theme.palette.darkgrey.darkgrey600,
              outline: "none",
            },
          }}
        />
      </Box>
      {selectedProfileImage !== defaultProfileImageSquare && (
        <>
          <Box
            sx={style}
            style={{ display: profileImage ? "flex" : "none" }}
            className={styles.uploadPhoto}
          >
            <TextField
              fullWidth
              value="Edit"
              onClick={handleEdit}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <label style={{ cursor: "pointer" }}>
                    <img src={editIcon} alt="upload" />
                  </label>
                ),
                style: {
                  backgroundColor: theme.palette.lightgrey.lightgrey00,
                  color: theme.palette.darkgrey.darkgrey600,
                  outline: "none",
                },
              }}
            />
          </Box>
          <Box
            sx={style}
            style={{ display: profileImage ? "flex" : "none" }}
            className={styles.uploadPhoto}
          >
            <TextField
              fullWidth
              value="Delete"
              onClick={handleDelete}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <label style={{ cursor: "pointer" }}>
                    <img src={deleteIcon} alt="upload" />
                  </label>
                ),
                style: {
                  backgroundColor: theme.palette.lightgrey.lightgrey00,
                  color: theme.palette.danger.red500,
                  outline: "none",
                },
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Content;
