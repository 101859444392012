import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import styles from "./style.module.css";
import HorizontalLinearStepper from "../../components/registration/stepper";
import { useLocation } from "react-router";
import useLocalStorageState from "../../hooks/useLocalStorageState";
import { useTheme } from "../../context/themeContext";
import { useSelector } from "react-redux";
import useSignup from "../../hooks/registeration/useSignup";
import useProtectedAuth from "../../hooks/useProtectedAuth";
import Thanks from "../../components/registration/thanks";

function Signup() {
  useProtectedAuth();
  const [isRegisterationFinished, setIsRegisterationFinished] =
    useLocalStorageState("registeration-finished", false);
  const location = useLocation();
  const { completedInfo } = useSelector((state) => state.registeration);
  const { theme, setDarkMode } = useTheme();

  useEffect(() => {
    if (completedInfo && isRegisterationFinished) return;
    if (!completedInfo && isRegisterationFinished)
      setIsRegisterationFinished(false);
  }, []);

  useEffect(() => {
    const shouldStoreQueries =
      location.search.split("=")[0] !== "?code" && location.search !== "";
    if (shouldStoreQueries) {
      console.log(location.search, "from setting location search");
      localStorage.setItem("starter-queries", location.search);
    }
    setDarkMode(true);
  }, []);

  return (
    <React.Fragment>
      {isRegisterationFinished && <Thanks />}
      {isRegisterationFinished === false && (
        <Box
          sx={{
            backgroundColor: theme.palette.lightgrey.lightgrey600,
            minHeight: "100vh",
            padding: "1.5rem",
          }}
        >
          <Box
            className={styles.parent}
            sx={{
              backgroundColor: theme.palette.lightgrey.lightgrey00,
            }}
          >
            <Box className={styles.basicLogo}>
              <Container className={styles.content}>
                <Box className={styles.text}>
                  <Typography
                    variant="body1"
                    align="center"
                    color={"#FFF"}
                    sx={{
                      fontSize: "36px",
                      fontWeight: "700",
                      lineHeight: "44px",
                      textTransform: "uppercase",
                      textAlign: "center",
                    }}
                  >
                    Creator Registration
                  </Typography>
                  <Typography variant="body1" align="center" color={"#FFF"}>
                    Apply in less than 60 seconds
                  </Typography>
                </Box>
              </Container>
            </Box>

            <Box className={styles.stepper}>
              <HorizontalLinearStepper
                setIsRegisterationFinished={setIsRegisterationFinished}
              />
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
}

export default Signup;
