import React from "react";
import "cropperjs/dist/cropper.min.css";
import { Box, Button } from "@mui/material";
import styles from "./style.module.css";
import { useTheme } from "../../../../../../context/themeContext";
import checkProfileComplete from "../../../../../../helpers/checkProfileComplete";
import {
  defaultProfileImageSquare,
  node_server,
} from "../../../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import useSettings from "../../../../../../hooks/useSettings";
import { updateProfileImageData } from "../../../../../../store/slices/profile/profileImageSlice";
import {
  setUser,
  setCommunityStatus as setUserStatus,
} from "../../../../../../store/slices/userSlice";
import axios from "axios";
import { setIsPageLoading } from "../../../../../../store/slices/loadingSlice";

const DeleteProfileImage = () => {
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const {
    value: user,
    communityStatus: userStatus,
    userSkills,
    collaboratorsTypes,
  } = useSelector((state) => state.user);
  const { recalculateScore } = useSettings();

  const handleDelete = async () => {
    try {
      dispatch(setIsPageLoading(true));

      dispatch(
        updateProfileImageData({
          isProfileImageDeleteOpen: false,
          selectedProfileImage: null,
        })
      );

      const { data: updatedUser, error: updatedUserError } = await axios.post(
        `${node_server}/api/user/update`,
        {
          profileImage: defaultProfileImageSquare,
          originalImage: defaultProfileImageSquare,
        },
        {
          withCredentials: true,
        }
      );

      if (updatedUserError) console.log(updatedUserError);
      dispatch(setUser(updatedUser));

      const userState = {
        user,
        setUser: (value) => dispatch(setUser(value)),
      };
      const statusState = {
        status: userStatus,
        setStatus: (value) => dispatch(setUserStatus(value)),
      };

      await checkProfileComplete(
        userState,
        statusState,
        userSkills,
        collaboratorsTypes
      );

      recalculateScore();
      dispatch(setIsPageLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setIsPageLoading(false));
    }
  };

  const handleCancel = () => {
    dispatch(
      updateProfileImageData({
        isProfileImageDeleteOpen: false,
        selectedProfileImage: null,
      })
    );
  };
  return (
    <Box className={styles.parent}>
      <Box
        className={styles.container}
        sx={{
          backgroundImage: `url(${user?.originalImage})`,
          width: {
            xs: "100%",
            md: "400px",
          },
        }}
      >
        <Box className={styles.frame}>
          <Box className={styles.content}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleDelete}
              sx={{
                textTransform: "capitalize",
                bgcolor: "#fff",
                color: theme.palette.danger.red500,
                fontWeight: 500,
              }}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={handleCancel}
              sx={{
                textTransform: "capitalize",
                bgcolor: "#fff",
                color: theme.palette.secondary.dark,
                fontWeight: 500,
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteProfileImage;
