import { useDispatch, useSelector } from "react-redux";
import { setIsButtonLoading } from "../../store/slices/loadingSlice";
import moment from "moment";
import { updateBirthDate, updateInvalid } from "../../store/slices/infoSlice";
import _ from "lodash";
import { calculateUserGeneration } from "../../helpers/calculateUserGeneration";
import axios from "axios";
import { node_server } from "../../constants";
import {
  setCollaboratorsTypes as setUserCollaboratorsTypes,
  setUser,
  setAgeRanges as setUserAgeRanges,
  setCommunityStatus as setUserStatus,
  setUserSkills,
} from "../../store/slices/userSlice";
import { updateProfileCompleteData } from "../../store/slices/profile/profileCompleteSlice";
import checkProfileComplete from "../../helpers/checkProfileComplete";
import generateFeeds from "../../helpers/generateFeeds";
import { setPage } from "../../store/slices/profile/discoverSlice";
import useSettings from "../useSettings";
import { useNavigate } from "react-router-dom";

const useAboutEdit = () => {
  const dispatch = useDispatch();
  const { data, copiedData } = useSelector((state) => state.aboutEdit);
  const {
    value: user,
    collaboratorsTypes: userCollaboratorsTypes,
    communityStatus: userStatus,
    userSkills,
  } = useSelector((state) => state.user);
  const {
    data: { collaborator },
  } = useSelector((state) => state.profileComplete);
  const { recalculateScore } = useSettings();
  const navigate = useNavigate();

  const checkUserHavePrimarySkill = async () => {
    const { data: userSkills, error } = await axios.get(
      `${node_server}/api/user/creator-skills`,
      {
        withCredentials: true,
      }
    );

    if (error) console.log(error);
    if (userSkills) {
      dispatch(setUserSkills(userSkills));
      const isHavePrimarySkill = userSkills.some(
        (skill) => skill.isPrimary === true
      );
      return isHavePrimarySkill;
    }
  };

  const handleSave = async () => {
    dispatch(setIsButtonLoading(true));

    const birth = Math.abs(moment(data.birthDate).diff(moment(), "year"));
    if (birth < 13) {
      dispatch(updateBirthDate({ isUnderAgeDialogOpen: true }));
      dispatch(setIsButtonLoading(false));
      return;
    }

    if (data.birthDate === "") {
      dispatch(
        updateInvalid({
          isInvalidOpen: true,
          message:
            "Invalid age range. Please enter the age with a valid range.",
        })
      );
      dispatch(setIsButtonLoading(false));
      return;
    }

    const difference = _.reduce(
      data,
      (result, value, key) => {
        if (!_.isEqual(copiedData[key], value)) {
          result[key] = value;
        }
        return result;
      },
      {}
    );

    let userFieldsToUpdate = {};
    for (const key in difference) {
      if (key !== "localSelectedCollabs" && key !== "localSelectedAgeRanges") {
        userFieldsToUpdate = {
          ...userFieldsToUpdate,
          [key]: difference[key],
        };
      }
    }

    const shouldUpdateCollaborators = Object.keys(difference).includes(
      "localSelectedCollabs"
    );

    const shouldUpdateAgeRanges = Object.keys(difference).includes(
      "localSelectedAgeRanges"
    );

    if (Object.keys(userFieldsToUpdate).length > 0) {
      if (Object.keys(userFieldsToUpdate).includes("birthDate")) {
        const generation = calculateUserGeneration(data.birthDate);
        userFieldsToUpdate = {
          ...userFieldsToUpdate,
          generation,
        };
      }
      console.log(userFieldsToUpdate, "userFieldsToUpdate");
      const { data: newUser, error: userError } = await axios.post(
        `${node_server}/api/user/update`,
        {
          ...userFieldsToUpdate,
          lastRecordChange: moment().format("YYYY-MM-DD"),
        },
        { withCredentials: true }
      );

      if (userError) console.log(userError);

      if (newUser) {
        dispatch(setUser(newUser));
      }
    }

    // collaborators types
    if (shouldUpdateCollaborators) {
      const { data: userCollabs, error: userCollabsError } = await axios.post(
        `${node_server}/api/user/collaborators`,
        {
          collaborators: data.localSelectedCollabs,
        },
        {
          withCredentials: true,
        }
      );

      if (userCollabsError) console.log(userCollabsError);
      if (userCollabs) {
        dispatch(setUserCollaboratorsTypes(userCollabs));
      }
    }

    // age ranges
    if (shouldUpdateAgeRanges) {
      const { data: userAgeRanges, error: userAgeRangesError } =
        await axios.post(
          `${node_server}/api/user/age-ranges`,
          {
            ageRanges: data.localSelectedAgeRanges,
          },
          {
            withCredentials: true,
          }
        );

      if (userAgeRangesError) console.log(userAgeRangesError);
      if (userAgeRanges) {
        dispatch(setUserAgeRanges(userAgeRanges));
      }
    }

    // for handling profile complete
    if (userCollaboratorsTypes.length > 0 && !collaborator) {
      dispatch(updateProfileCompleteData({ collaborator: true }));
    } else if (userCollaboratorsTypes.length === 0 && collaborator) {
      dispatch(updateProfileCompleteData({ collaborator: false }));
    }

    const havePrimarySkill = await checkUserHavePrimarySkill();

    const allAccessMember =
      havePrimarySkill &&
      user.realName &&
      user.profileImage &&
      user.tagline &&
      user.mobile &&
      user.birthDate &&
      user.country &&
      user.publicEmail;

    if (allAccessMember) {
      const { data: updatedStatus, error: statusError } = await axios.patch(
        `${node_server}/api/user/status`,
        {
          status: "All Access Member",
          dateAcquired: moment().format("YYYY-MM-DD"),
        },
        { params: { status: "Incomplete" }, withCredentials: true }
      );

      if (statusError) console.log(statusError);
      if (updatedStatus) {
        const userStateStatus = userStatus.filter(
          (item) => item.id !== updatedStatus.id
        );
        dispatch(setUserStatus([...userStateStatus, updatedStatus]));
      }
    }

    dispatch(setIsButtonLoading(false));
    navigate("/profile");

    const userState = { user, setUser: (value) => dispatch(setUser(value)) };
    const statusState = {
      status: userStatus,
      setStatus: (value) => dispatch(setUserStatus(value)),
    };

    await checkProfileComplete(
      userState,
      statusState,
      userSkills,
      data.localSelectedCollabs
    );

    if (user.isProfileComplete) {
      const feedResponse = await generateFeeds(user);

      if (feedResponse === "User feed generated") {
        dispatch(setPage(1));
      }
    }

    recalculateScore();
  };

  return { handleSave };
};

export default useAboutEdit;
