import { Box, Divider, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../../../../components/loading/button";
import { useTheme } from "../../../../context/themeContext";
import { useDispatch, useSelector } from "react-redux";
import PersonalDetails from "./components/personalDetails";
import ChoosePronouns from "./components/choosePronouns";
import CollaborateWith from "./components/collaborateWith";
import AgeRanges from "./components/ageRanges";
import { resetAboutEditState } from "../../../../store/slices/profile/aboutEditSlice";
import useProfileComplete from "../../../../hooks/useProfileComplete";
// import useDiscover from "../../../../hooks/useDiscover";
import {
  setCollaboratorsTypes as setUserCollaboratorsTypes,
  setAgeRanges as setUserAgeRanges,
} from "../../../../store/slices/userSlice";
import useProtectedAuth from "../../../../hooks/useProtectedAuth";
import UnderAgeDialog from "../../../../components/registration/steps/step3/components/underAge";
import axios from "axios";
import { node_server } from "../../../../constants";
import _ from "lodash";
import useAboutEdit from "../../../../hooks/profile/useAboutEdit";
import { setIsPageLoading } from "../../../../store/slices/loadingSlice";
import Loading from "../../../../components/loading/default";
import { sortArrayByAgeCategory } from "../../../../helpers/sortArrays";
import Invalid from "../../../../components/common/Invalid";

function AboutEdit() {
  useProtectedAuth();
  const { theme } = useTheme();
  useProfileComplete();
  const {
    value: user,
    collaboratorsTypes: userCollaboratorsTypes,
    ageRanges: userAgeRanges,
  } = useSelector((state) => state.user);
  // useDiscover(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pageLoading, buttonLoading } = useSelector(
    (state) => state.isLoading
  );
  const { data, copiedData } = useSelector((state) => state.aboutEdit);

  const [IsSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  const { handleSave } = useAboutEdit();

  const handleCancel = () => {
    dispatch(resetAboutEditState());
    navigate("/profile");
  };

  useEffect(() => {
    if (!user) return;
    const fetchData = async () => {
      const { data, error } = await axios.get(
        `${node_server}/api/profile/about-edit`,
        { withCredentials: true }
      );

      if (error) console.log(error);
      if (data) {
        const sortedAgeRanges = sortArrayByAgeCategory(data.ageRanges);
        dispatch(setUserAgeRanges(sortedAgeRanges));
        dispatch(setUserCollaboratorsTypes(data.collaborators));
      }
    };

    if (userCollaboratorsTypes.length === 0 || userAgeRanges.length === 0) {
      fetchData();
    }
    dispatch(setIsPageLoading(false));
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setIsPageLoading(true));
  }, []);

  useEffect(() => {
    setIsSaveButtonDisabled(true);
  }, [buttonLoading]);

  useEffect(() => {
    if (!user) return;
    const check = _.isEqual(Object.values(copiedData), Object.values(data));

    if (check) setIsSaveButtonDisabled(true);
    else setIsSaveButtonDisabled(false);
  }, [data, copiedData]);

  if (pageLoading) {
    return <Loading />;
  }

  return (
    <Box
      className={styles.dialog}
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey00,
      }}
    >
      <Invalid />
      <UnderAgeDialog altText="Please provide a valid birthdate" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        <Box className={styles.communityStatus}>
          <PersonalDetails />
          <ChoosePronouns />
        </Box>
      </Box>
      <Divider />
      <Box
        className={styles.basicsType}
        sx={{
          "& .MuiList-padding": {
            p: "0px",
          },
          "& .MuiList-root ": {
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          },
        }}
      >
        <CollaborateWith />
        <AgeRanges />
        <Box className={styles.buttons}>
          <Button
            onClick={handleSave}
            disabled={IsSaveButtonDisabled}
            variant="contained"
            sx={{
              width: {
                xs: "100%",
              },
            }}
          >
            {buttonLoading ? <LoadingButton /> : "Save"}
          </Button>
          <Button
            onClick={handleCancel}
            variant="text"
            sx={{
              color: theme.palette.darkgrey.darkgrey400,
              p: "0px",
              textTransform: "capitalize",
              fontSize: "16px",
              fontWeight: "500",
              width: {
                xs: "100%",
              },
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default AboutEdit;
