import {
  Autocomplete,
  Box,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTheme } from "../../../../../../context/themeContext";
import useCountries from "../../../../../../helpers/getAllCountries";
import { useDispatch, useSelector } from "react-redux";
import { updateRegistrationData } from "../../../../../../store/slices/registerationSlice";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

const Content = () => {
  const { theme, darkMode } = useTheme();

  const dispatch = useDispatch();
  const {
    data: {
      country,
      postalCode,
      birthDate,
      phoneNumber,
      countryCode,
      pronouns,
    },
  } = useSelector((state) => state.registeration);

  const countries = useCountries();
  const countriesNames = countries.map((country) => country.name);

  const handleBirthdate = (newDate) => {
    const formattedDate = moment(newDate).format("MM-DD-YYYY");
    const birthYear = moment(newDate).year();
    const currentYear = moment().year();
    if (birthYear > currentYear || String(birthYear).length < 4) {
      dispatch(updateRegistrationData({ birthDate: "" }));
      return;
    }
    dispatch(updateRegistrationData({ birthDate: formattedDate }));
  };

  useEffect(() => {
    if (Object.keys(countries).length !== 0) {
      const result = countries.find((item) => item.name === country);
      if (result && result.idd) {
        if (country === "United States") {
          dispatch(updateRegistrationData({ countryCode: result.idd.root }));
        } else {
          dispatch(
            updateRegistrationData({
              countryCode: result.idd.root + result.idd.suffixes[0],
            })
          );
        }
      }
    }
  }, [country]);

  return (
    <Box
      sx={{
        mt: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Autocomplete
        sx={{
          bgcolor: theme.palette.lightgrey.lightgrey00,
        }}
        fullWidth
        options={countriesNames}
        getOptionLabel={(option) => option}
        value={country}
        onChange={(event, newValue) =>
          dispatch(updateRegistrationData({ country: newValue }))
        }
        renderInput={(params) => (
          <TextField {...params} label="Select Country" variant="outlined" />
        )}
      />
      <TextField
        label="Postal Code"
        variant="outlined"
        value={postalCode}
        onChange={(e) =>
          dispatch(updateRegistrationData({ postalCode: e.target.value }))
        }
        sx={{
          backgroundColor: !darkMode && "#fff",
          width: "50%",
        }}
      />

      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label="Birth Date"
          value={moment(birthDate)}
          onChange={handleBirthdate}
          sx={{
            color: "#fff !important",
            "& label.Mui-error": {
              color: theme.palette.darkgrey.darkgrey500,
            },
            "& .css-adc0zx-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "rgba(255, 255, 255, 0.23)",
              },
            "& label.Mui-focused": { color: theme.palette.primary.main },
            "& .css-adc0zx-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: theme.palette.primary.main,
              },
          }}
        />
      </LocalizationProvider>

      <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
        <FormLabel
          id="demo-row-radio-buttons-group-label"
          sx={{ color: theme.palette.darkgrey.darkgrey600 }}
        >
          Choose your pronouns
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          sx={{
            color: theme.palette.darkgrey.darkgrey500,
            fontSize: "14px",
          }}
          onChange={(e) =>
            dispatch(updateRegistrationData({ pronouns: e.target.value }))
          }
          value={pronouns}
        >
          <FormControlLabel value="He/Him" control={<Radio />} label="He/Him" />
          <FormControlLabel
            value="She/Her"
            control={<Radio />}
            label="She/Her"
          />
          <FormControlLabel
            value="They/Them"
            control={<Radio />}
            label="They/Them"
          />
        </RadioGroup>
      </Box>

      <TextField
        label="Phone Number"
        name="phone"
        value={phoneNumber}
        onChange={(e) =>
          dispatch(updateRegistrationData({ phoneNumber: e.target.value }))
        }
        variant="outlined"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{countryCode}</InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default Content;
