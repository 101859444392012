import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setIsPageLoading } from "../store/slices/loadingSlice";
import moment from "moment";
import {
  setAgeRanges,
  setCollaboratorsTypes,
  setCommunityStatus,
  setCreatorTypes,
  setUser,
  setUserSkills,
} from "../store/slices/userSlice";
import { getAddressInfo } from "../helpers/getAddressInfo";
import extractUTMParameters from "../helpers/extractUTMParameters";
import { calculateUserGeneration } from "../helpers/calculateUserGeneration";
import TagManager from "react-gtm-module";
import axios from "axios";
import { node_server } from "../constants";

const useProtectedAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const redirectUrl = localStorage.getItem("redirectUrl");

  const checkRedirectUrl = () => {
    if (!redirectUrl) return;
    if (location.pathname === "/" || location.pathname === "/onboarding") {
      navigate(redirectUrl);
    }
  };

  const checkRegistered = async (user) => {
    const shouldRegisterFromReversed = localStorage.getItem(
      "shouldRegisterFromReversed"
    );

    if (shouldRegisterFromReversed === "true") {
      const parsedRegisteredData = JSON.parse(
        localStorage.getItem("reversedRegistrationData")
      );

      const {
        selectedCollaboratorsTypes,
        creatorTypes,
        birthdate,
        country,
        postalCode,
        pronouns,
        phoneNumber,
        countryCode,
      } = parsedRegisteredData;

      const addressData = await getAddressInfo(postalCode, country);
      const city = addressData?.city || "";
      const county = addressData?.county || "";
      const state = addressData?.state || "";

      const { utm_source, utm_campaign, utm_content } = extractUTMParameters(
        localStorage.getItem("starter-queries")
      );

      const { data: updatedUser, error: updatedUserError } = await axios.post(
        `${node_server}/api/user/update`,
        {
          country,
          generation: calculateUserGeneration(birthdate),
          postal_code: postalCode,
          birth_date: moment(birthdate, "MM-DD-YYYY").format("YYYY-MM-DD"),
          signup_completed: true,
          pronouns: pronouns,
          last_record_change: new Date(),
          city,
          county,
          state,
          utm_info: localStorage.getItem("starter-queries"),
          utm_source,
          utm_campaign,
          utm_content,
          mobile: countryCode + phoneNumber,
        },
        {
          withCredentials: true,
        }
      );

      if (updatedUserError) console.log(updatedUserError);

      if (updatedUser) {
        localStorage.removeItem("starter-queries");

        const { data, error } = await axios.post(
          `${node_server}/api/registration/user-details`,
          {
            creatorTypes,
            selectedCollaboratorsTypes,
            ageRanges: ["X", "X+", "Y", "Y+", "Z", "Z+"],
            status: ["Creator"],
          },
          {
            withCredentials: true,
          }
        );
        if (error) console.log(error);
        if (data) {
          dispatch(setCreatorTypes(data.creatorTypes));
          dispatch(setCollaboratorsTypes(data.collaboratorsTypes));
          dispatch(setAgeRanges(data.ageRanges));
          dispatch(setCommunityStatus(data.status));
        }

        const { data: skills, error: skillsError } = await axios.post(
          `${node_server}/api/registration/user-skills`,
          {
            creatorTypes,
          },
          {
            withCredentials: true,
          }
        );

        if (skillsError) console.log(skillsError);
        if (skills) dispatch(setUserSkills(skills));

        localStorage.removeItem("shouldRegisterFromReversed");
        localStorage.removeItem("reversedRegistrationData");

        dispatch(setUser(updatedUser));
        dispatch(setIsPageLoading(false));
        if (updatedUser.signup_completed) {
          checkRedirectUrl();
        } else {
          navigate("/onboarding");
        }

        TagManager.dataLayer({
          dataLayer: {
            event: "successful registration",
          },
        });
      }
    } else {
      dispatch(setUser(user));
      dispatch(setIsPageLoading(false));
      if (user.signupCompleted) {
        checkRedirectUrl();
      } else {
        navigate("/onboarding");
      }
    }
  };

  useEffect(() => {
    const checkUser = async () => {
      dispatch(setIsPageLoading(true));

      const response = await axios.get(`${node_server}/auth/check`, {
        withCredentials: true,
      });
      const { isAuthenticated, user } = response.data;

      if (isAuthenticated) {
        checkRegistered(user);
      } else {
        dispatch(setIsPageLoading(false));
        navigate("/");
      }
    };

    checkUser();
  }, []);

  useEffect(() => {
    if (
      !(
        location.pathname === "/" ||
        location.pathname === "/signup" ||
        location.pathname === "/onboarding"
      )
    ) {
      localStorage.setItem("redirectUrl", location.pathname);
    }
  }, []);
};

export default useProtectedAuth;
