import React from "react";
import styles from "./style.module.css";
import { Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import Top from "./top";
import Menu from "./menu";
import Mode from "./mode";
import { useTheme } from "../../../context/themeContext";
import Loading from "../../../components/loading/default";
import { useSelector } from "react-redux";

const Landing = () => {
  const { theme } = useTheme();
  const { pageLoading } = useSelector((state) => state.isLoading);

  // if (pageLoading) {
  //   return <Loading />;
  // }
  return (
    <Box
      className={styles.landing}
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey600,
      }}
    >
      <Box className={styles.container}>
        <Box className={styles.sideDrawer}>
          <Top />
          <Divider sx={{ width: "100%" }} />
          <Menu />
          <Mode />
        </Box>
      </Box>
    </Box>
  );
};
export default Landing;
