import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import styles from "./styles.module.css";
import { useTheme } from "../../../../../context/themeContext";
import { useSelector } from "react-redux";

const UserLocation = () => {
  const { theme, darkMode } = useTheme();
  const user = useSelector((state) => state.user.value);

  const userLocaton = [
    {
      label: "Country",
      value: user.country,
    },
    {
      label: "Postal Code",
      value: user.postalCode,
    },
    {
      label: "City, State, County",
      value: user?.city + ", " + user?.state + ", " + user?.county,
    },
  ];

  return (
    <Box
      className={styles.communityStatus}
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey00,
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: theme.palette.darkgrey.darkgrey600,
          fontSize: "18px",
          fontWeight: 700,
        }}
      >
        My Location
      </Typography>

      <Box className={styles.content}>
        {userLocaton.map((item) => (
          <Box key={item.label} className={styles.same}>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.darkgrey.darkgrey300,
              }}
            >
              {item.label}
            </Typography>
            {item.label === "Country" ? (
              <Chip
                label={item.value}
                sx={{
                  color: darkMode
                    ? theme.palette.secondary.blue100
                    : theme.palette.secondary.blue700,
                  bgcolor: !darkMode && theme.palette.secondary.light,
                  fontWeight: 500,
                  fontSize: "14px",
                  height: "32px",
                  "& .css-6od3lo-MuiChip-label": {
                    padding: "0px 16px",
                  },
                }}
              />
            ) : item.label === "Postal Code" ? (
              <Box className={styles.detail}>
                <Typography
                  variant="body1"
                  sx={{
                    color: darkMode
                      ? theme.palette.darkgrey.darkgreybody50
                      : theme.palette.darkgrey.darkgrey600,
                  }}
                >
                  {item.value}
                </Typography>
              </Box>
            ) : (
              <Typography
                variant="body1"
                sx={{
                  color: darkMode
                    ? theme.palette.darkgrey.darkgreybody50
                    : theme.palette.darkgrey.darkgrey600,
                }}
              >
                {item.value}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default UserLocation;
