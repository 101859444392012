import { Box, Chip, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import styles from "./styles.module.css";
import { useTheme } from "../../../../../context/themeContext";
import { Link } from "@mui/icons-material";
import moment from "moment";
import { defaultProfilePrefix } from "../../../../../constants";
import { useSelector } from "react-redux";

const UserInfo = () => {
  const [isCopied, setIsCopied] = useState(false);
  const { theme, darkMode } = useTheme();
  const user = useSelector((state) => state.user.value);

  const handleCopyLink = async (linkToCopy) => {
    try {
      await navigator.clipboard.writeText(linkToCopy);
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
  };

  const userInfo = [
    {
      label: "My Real Name",
      value: user.realName,
    },
    {
      label: "My Nickname",
      value: user.nickName,
    },
    {
      label: "Tagline",
      value: user.tagline,
    },
    {
      label: "Profile Link",
      value: defaultProfilePrefix + user.id,
    },
    {
      label: "Public Email Address",
      value: user.publicEmail,
    },
    {
      label: "Registered Email Address",
      value: user.email,
    },
    {
      label: "Mobile Number",
      value: user.mobile,
    },
    {
      label: "Birth Date",
      value: moment(user.birthDate).format("MM-DD-YYYY"),
    },
    {
      label: "Pronouns",
      value: user.pronouns,
    },
  ];

  const generation = user.generation;

  return (
    <Box
      className={styles.communityStatus}
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey00,
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: theme.palette.darkgrey.darkgrey600,
          fontSize: "18px",
          fontWeight: 700,
        }}
      >
        Personal Details
      </Typography>
      <Box className={styles.content}>
        {userInfo.map((item) => (
          <Box key={item.label} className={styles.same}>
            <Box className={styles.title}>
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.darkgrey.darkgrey300,
                }}
              >
                {item.label}
              </Typography>
            </Box>
            {item.label === "Pronouns" ? (
              <Chip
                label={item.value}
                sx={{
                  color: darkMode
                    ? theme.palette.primary.purpel100
                    : theme.palette.primary.main,
                  bgcolor: !darkMode && theme.palette.primary.purpel50,
                }}
              />
            ) : item.label === "Birth Date" ||
              item.label === "Mobile Number" ||
              item.label === "Registered Email Address" ? (
              <Box className={styles.container}>
                <Box className={styles.detail}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: darkMode
                        ? theme.palette.darkgrey.darkgreybody50
                        : theme.palette.darkgrey.darkgrey600,
                    }}
                  >
                    {item.value}
                  </Typography>
                </Box>
                {item.label === "Birth Date" && (
                  <Chip
                    label={"Gen " + generation}
                    sx={{
                      color: darkMode
                        ? theme.palette.secondary.blue100
                        : theme.palette.secondary.blue700,
                      bgcolor: !darkMode && theme.palette.secondary.light,
                      fontWeight: 500,
                      fontSize: "14px",
                      height: "32px",
                      "& .css-6od3lo-MuiChip-label": {
                        padding: "0px 16px",
                      },
                    }}
                  />
                )}
              </Box>
            ) : (
              <Box className={styles.container}>
                <Typography
                  variant="body1"
                  sx={{
                    color: darkMode
                      ? theme.palette.darkgrey.darkgreybody50
                      : theme.palette.darkgrey.darkgrey600,
                  }}
                >
                  {item.value}
                </Typography>
                {item.label === "Profile Link" && (
                  <Box>
                    <IconButton
                      onClick={() => handleCopyLink(item.value)}
                      sx={{
                        bgcolor: darkMode
                          ? "#525252"
                          : theme.palette.secondary.light,
                        display: isCopied ? "none" : "flex",
                        padding: "6px",
                      }}
                    >
                      <Link sx={{ width: "1.3rem", height: "1.3rem" }} />
                    </IconButton>
                    {isCopied && (
                      <span
                        style={{
                          marginLeft: "5px",
                          color: theme.palette.secondary.blue100,
                        }}
                      >
                        Copied!
                      </span>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default UserInfo;
