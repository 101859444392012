import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "../../../context/themeContext";
import { useDispatch, useSelector } from "react-redux";
import { updateInvalid } from "../../../store/slices/infoSlice";

const Invalid = () => {
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const { isInvalidOpen, message } = useSelector((state) => state.info.invalid);

  const handleClick = () => {
    dispatch(updateInvalid({ isInvalidOpen: false, message: "" }));
  };

  return (
    <Modal
      open={isInvalidOpen}
      onClose={handleClick}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "25px",
        alignSelf: "stretch",
        flex: "1 0 0",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          padding: "2.5rem 1.5rem",
          borderRadius: "8px",
          width: "330px",
          backgroundColor: theme.palette.lightgrey.lightgrey00,
        }}
      >
        <Typography
          variant="body1"
          color={theme.palette.darkgrey.darkgrey400}
          sx={{
            textAlign: "center",
            marginBottom: "1rem",
            fontSize: "1.1rem",
          }}
        >
          <span style={{ color: theme.palette.darkgrey.darkgrey600 }}>
            Oh shoot!{" "}
          </span>
          {message}
        </Typography>
        <Button variant="contained" onClick={handleClick} fullWidth>
          Okay!
        </Button>
      </Box>
    </Modal>
  );
};

export default Invalid;
