import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: [],
  availableFilters: [],
  selectedFilters: [],
  resultsRef: null,
  results: [],
  isLoading: false,
};

const projectFiltersSlice = createSlice({
  name: "projectFilters",
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setAvailableFilters: (state, action) => {
      state.availableFilters = action.payload;
    },
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setResultsRef: (state, action) => {
      state.resultsRef = action.payload;
    },
    setResults: (state, action) => {
      state.results = action.payload;
    },
  },
});

export const {
  setFilters,
  setAvailableFilters,
  setSelectedFilters,
  setIsLoading,
  setResultsRef,
  setResults,
} = projectFiltersSlice.actions;

export default projectFiltersSlice.reducer;
