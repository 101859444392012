import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    imageAdded: false,
    skillComplete: false,
    collaborator: false,
    profileComplete: false,
  },
  isUserProfileComplete: false,
};

const profileCompleteSlice = createSlice({
  name: "profileComplete",
  initialState,
  reducers: {
    updateProfileCompleteData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    setIsUserProfileComplete: (state, action) => {
      state.isUserProfileComplete = action.payload;
    },
  },
});

export const { updateProfileCompleteData, setIsUserProfileComplete } =
  profileCompleteSlice.actions;

export default profileCompleteSlice.reducer;
