import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import styles from "./styles.module.css";
import { useTheme } from "../../../../../context/themeContext";
import { useSelector } from "react-redux";
import LoadingButton from "../../../../../components/loading/button";
import UserAgeRanges from "../userAgeRanges";

const UserCollaborators = () => {
  const { theme } = useTheme();
  const userCollaboratorsTypes = useSelector(
    (state) => state.user.collaboratorsTypes
  );

  return (
    <Box
      className={styles.communityStatus}
      sx={{
        bgcolor: theme.palette.lightgrey.lightgrey00,
      }}
    >
      <Box className={styles.content}>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.darkgrey.darkgrey600,
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          I am looking to collaborate with:
        </Typography>
        <Box
          className={styles.tags}
          sx={{
            flexWrap: "wrap",
          }}
        >
          {userCollaboratorsTypes?.length === 0 ? (
            <LoadingButton />
          ) : userCollaboratorsTypes?.length === 0 ? (
            <Typography
              variant="body2"
              sx={{ color: theme.palette.darkgrey.darkgrey300 }}
            >
              Edit your profile to add collaborators types !
            </Typography>
          ) : (
            userCollaboratorsTypes?.map((item) => (
              <Chip key={item.id} label={item.displayName} />
            ))
          )}
        </Box>
      </Box>
      <Box className={styles.content}>
        <UserAgeRanges />
      </Box>
    </Box>
  );
};

export default UserCollaborators;
