import { defaultProfileImageSquare, node_server } from "../constants";
import axios from "axios";

const checkSkills = (skills) => {
  return skills.map((skill) => {
    if (
      skill.name &&
      skill.description &&
      skill.level &&
      skill.started_training_age
    )
      return true;
    else return false;
  });
};

const checkProfileComplete = async (
  userState,
  statusState,
  userSkills,
  collabs
) => {
  let { user, setUser } = userState;
  let { status, setStatus } = statusState;
  let isSkillComplete;

  // check if user have skill completed
  if (userSkills?.length > 0) {
    isSkillComplete = checkSkills(userSkills);
  }
  // else {
  //   const { data: skillsData, error: skillsError } = await axios.get(
  //     `${node_server}/api/user/creator-skills`,
  //     {
  //       withCredentials: true,
  //     }
  //   );

  //   if (skillsError) console.log(skillsError);
  //   if (skillsData) {
  //     isSkillComplete = checkSkills(skillsData);
  //   }
  // }

  // check if profile complete or not
  if (
    isSkillComplete?.includes(true) &&
    user.profileImage !== null &&
    user.profileImage !== defaultProfileImageSquare &&
    collabs.length > 0
  ) {
    if (user.isProfileComplete === false) {
      const { data: updatedStatus, error: statusError } = await axios.patch(
        `${node_server}/api/user/status`,
        {
          isActive: false,
        },
        {
          params: { status: "Incomplete" },
          withCredentials: true,
        }
      );

      if (statusError) console.log(statusError);
      if (updatedStatus) {
        const userStateStatus = status.filter(
          (item) => item.id !== updatedStatus.id
        );
        setStatus([...userStateStatus, updatedStatus]);
      }

      // update profile status
      const { data: updatedUser, error: userError } = await axios.post(
        `${node_server}/api/user/update`,
        {
          isProfileComplete: true,
        },
        {
          withCredentials: true,
        }
      );

      if (userError) console.log(userError);
      if (updatedUser) setUser(updatedUser);
    }
  } else {
    if (user?.isProfileComplete === true) {
      // update profile status
      const { data: updatedUser, error: userError } = await axios.post(
        `${node_server}/api/user/update`,
        {
          isProfileComplete: false,
        },
        {
          withCredentials: true,
        }
      );

      if (userError) console.log(userError);
      if (updatedUser) setUser(updatedUser);

      // update is_active status
      const { data: statusData, error: statusError } = await axios.patch(
        `${node_server}/api/user/status`,
        {
          isActive: true,
        },
        {
          params: { status: "Incomplete" },
          withCredentials: true,
        }
      );

      if (statusError) console.log(statusError);
      if (statusData) {
        const userStateStatus = status.filter(
          (item) => item.id !== statusData.id
        );
        setStatus([...userStateStatus, statusData]);
      }
    }
  }
};

export default checkProfileComplete;
