import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedSkillImage: null,
  croppedImage: null,
  isModalSkillOpen: false,
  isDeleteSkillImageOpen: false,
};

const skillImageSlice = createSlice({
  name: "skillImage",
  initialState,
  reducers: {
    setSelectedSkillImage: (state, action) => {
      state.selectedSkillImage = action.payload;
    },
    setCroppedImage: (state, action) => {
      state.croppedImage = action.payload;
    },
    setIsModalSkillOpen: (state, action) => {
      state.isModalSkillOpen = action.payload;
    },
    setIsDeleteSkillImageOpen: (state, action) => {
      state.isDeleteSkillImageOpen = action.payload;
    },
  },
});

export const {
  setSelectedSkillImage,
  setCroppedImage,
  setIsModalSkillOpen,
  setIsDeleteSkillImageOpen,
} = skillImageSlice.actions;

export default skillImageSlice.reducer;
