import { useState } from "react";
import Step1 from "../steps/step1";
import Step2 from "../steps/step2";
import Step3 from "../steps/step3";
import Step4 from "../steps/step4";
import useStepper from "../../../hooks/registeration/useStepper";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { useTheme } from "../../../context/themeContext";
import { useLocation } from "react-router-dom";

const HorizontalLinearStepper = ({ setIsRegisterationFinished }) => {
  const { theme } = useTheme();
  const { ColorlibConnector, ColorlibStepIcon } = useStepper();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const { pathname } = useLocation();

  let steps;
  if (pathname === "/onboarding") {
    steps = [
      { label: "Priority Code" },
      { label: "Creator Type" },
      { label: "Basics" },
      { label: "Collabs" },
    ];
  } else {
    steps = [
      { label: "Creator Type" },
      { label: "Basics" },
      { label: "Collabs" },
    ];
  }

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleStep = (e, index) => {
    const elClassList = e.target.classList;
    if (elClassList.contains("Mui-completed")) {
      setActiveStep(index);
    }
  };

  return (
    <Box
      sx={{
        width: {
          xs: "100%",
        },
      }}
    >
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
        sx={{
          padding: "1.5rem",
        }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step
              key={label.label}
              {...stepProps}
              onClick={(e) => handleStep(e, index)}
              sx={{ "&>*": { pointerEvents: "none" } }}
            >
              <StepLabel
                StepIconComponent={ColorlibStepIcon}
                {...labelProps}
                sx={{
                  flexDirection: "column",
                  alignItems: "center",
                  ".MuiStepLabel-iconContainer": {
                    p: "0",
                    m: "0",
                  },
                  "& .MuiStepLabel-label": {
                    fontSize: {
                      xs: "12px",
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      height: "8px",
                      bgcolor: theme.palette.darkgrey.darkgrey100,
                      width: "90%",
                      position: "absolute",
                      top: "2.5rem",
                      left: "0",
                      borderRadius: "16px",
                      m: "0 5px",
                    },
                    "&.Mui-active": {
                      color: theme.palette.darkgrey.darkgrey500,
                      "&:before": {
                        bgcolor: theme.palette.primary.purpel100,
                      },
                    },
                    "&.Mui-completed": {
                      color: theme.palette.darkgrey.darkgrey500,
                      "&:before": {
                        bgcolor: theme.palette.primary.main,
                      },
                    },
                    "&.MuiStepLabel-alternativeLabel": {
                      marginTop: "1px",
                    },
                  },
                }}
              >
                {label.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <></>
      ) : pathname === "/onboarding" ? (
        <>
          {activeStep === 0 && <Step1 clickNext={handleNext} />}
          {activeStep === 1 && <Step2 clickNext={handleNext} />}
          {activeStep === 2 && <Step3 clickNext={handleNext} />}
          {activeStep === 3 && (
            <Step4 setIsRegisterationFinished={setIsRegisterationFinished} />
          )}
        </>
      ) : (
        <>
          {activeStep === 0 && <Step2 clickNext={handleNext} />}
          {activeStep === 1 && <Step3 clickNext={handleNext} />}
          {activeStep === 2 && (
            <Step4 setIsRegisterationFinished={setIsRegisterationFinished} />
          )}
        </>
      )}
    </Box>
  );
};

export default HorizontalLinearStepper;
