import { Box, Button, Link } from "@mui/material";
import React from "react";
import styles from "./styles.module.css";
import { useTheme } from "../../../../context/themeContext";
import google from "../../../../assets/images/login/Vector.svg";
import { node_server } from "../../../../constants";
import { useDispatch } from "react-redux";
import { setIsPageLoading } from "../../../../store/slices/loadingSlice";

const LoginButton = () => {
  const { theme } = useTheme();
  const redirectUrl = localStorage.getItem("redirectUrl");
  const dispatch = useDispatch();

  return (
    <Box className={styles.buttonContainer}>
      <Button
        sx={{
          backgroundColor: "#FFF",
          color: theme?.palette.primary.main,
          padding: "0",
        }}
        className={styles.button}
        onClick={() => dispatch(setIsPageLoading(true))}
      >
        <Link
          href={`${node_server}/auth/google${
            redirectUrl ? `?redirect_url=${redirectUrl}` : ""
          }`}
          className={styles.link}
        >
          <img
            style={{
              padding: "10px",
              backgroundColor: "#F5F2F6",
              borderRadius: "4px",
            }}
            src={google}
            alt="google"
          />
          Sign in with Google
        </Link>
      </Button>
    </Box>
  );
};

export default LoginButton;
