import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAboutEditData } from "../../../../../../store/slices/profile/aboutEditSlice";
import { useTheme } from "../../../../../../context/themeContext";

const ChoosePronouns = () => {
  const { theme, darkMode } = useTheme();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.aboutEdit);
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(data.pronouns);
  }, [data]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
      <FormLabel
        id="demo-row-radio-buttons-group-label"
        sx={{
          color: darkMode ? "#fff" : theme.palette.darkgrey.darkgrey500,
        }}
      >
        Choose your pronouns
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="pronouns"
        name="pronouns"
        sx={{
          color: theme.palette.darkgrey.darkgrey500,
          fontSize: "14px",
        }}
        value={value}
        onChange={(e) =>
          dispatch(updateAboutEditData({ pronouns: e.target.value }))
        }
      >
        <FormControlLabel
          value="He/Him"
          name="he/him"
          control={<Radio />}
          label="He/Him"
        />
        <FormControlLabel
          value="She/Her"
          name="she/her"
          control={<Radio />}
          label="She/Her"
        />
        <FormControlLabel
          value="They/Them"
          name="they/them"
          control={<Radio />}
          label="They/Them"
        />
      </RadioGroup>
    </Box>
  );
};

export default ChoosePronouns;
