import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAllCountries } from "../store/slices/infoSlice";
import axios from "axios";

const useCountries = () => {
  const dispatch = useDispatch();
  const { allCountries } = useSelector((state) => state.info);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const { data } = await axios("https://restcountries.com/v3.1/all");
        const storedData = data.map((country) => {
          return { name: country.name.common, idd: country.idd };
        });
        dispatch(setAllCountries(storedData));
      } catch (error) {
        console.error("Error fetching countries:", error);
        dispatch(setAllCountries([]));
      }
    };

    if (allCountries.length === 0) fetchCountries();
  }, []);

  const getSortedCountryNames = () => {
    const sortedCountries = [...allCountries];
    sortedCountries.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
    return sortedCountries;
  };

  return getSortedCountryNames();
};

export default useCountries;
