import React from "react";
import "cropperjs/dist/cropper.min.css";
import { Box } from "@mui/material";
import styles from "./style.module.css";
import ImageCropper from "./cropper";
import supabase from "../../../../../../supabase/client";
import { uploadImage } from "../../../../../../helpers/uploadImageToS3";
import imageCompression from "browser-image-compression";
import { useDispatch, useSelector } from "react-redux";
import { setIsPageLoading } from "../../../../../../store/slices/loadingSlice";
import { updateProfileImageData } from "../../../../../../store/slices/profile/profileImageSlice";
import {
  setIsModalSkillOpen,
  setSelectedSkillImage,
} from "../../../../../../store/slices/profile/skillImageSlice";
import useSkillsBar from "../../../../../../hooks/useSkillsBar";

const EditSkillImage = () => {
  const dispatch = useDispatch();
  const { selectedSkillImage } = useSelector((state) => state.skillImage);
  const { activeSkill, setActiveSkill } = useSkillsBar();

  const handleCrop = async (croppedImage) => {
    try {
      // Convert the Uint8Array to a Blob
      const blob = new Blob([croppedImage], { type: "image/png" });
      const originalBlob = blob;

      // Compress the image using browser-image-compression library
      const options = {
        maxSizeMB: 1, // Set the maximum size of the compressed image to 0.5 MB
        maxWidthOrHeight: 600, // Set the maximum width or height of the compressed image
        useWebWorker: true, // Use a web worker for faster compression
        quality: 1, // Adjust the quality parameter (0.7 is a good starting point)
      };

      const compressedBlob = await imageCompression(originalBlob, options);

      const blobURL = await uploadImage(
        compressedBlob,
        "skill-cover-image-" + activeSkill.id + "-v-" + new Date().getTime()
      );

      // setActiveSkill({ ...activeSkill, skill_cover_image: blobURL });
      dispatch(updateProfileImageData({ croppedProfileImage: blobURL }));
      dispatch(setSelectedSkillImage(null));
      dispatch(setIsModalSkillOpen(false));
      dispatch(setIsPageLoading(true));

      const { error } = await supabase
        .from("creator_skills")
        .update({
          skill_cover_image: blobURL,
        })
        .eq("id", activeSkill?.id);

      if (error) console.error("Error uploading skill image:", error);

      const { data: updatedSkill, error: updatedSkillError } = await supabase
        .from("creator_skills")
        .select("*")
        .eq("id", activeSkill.id);

      if (updatedSkillError) console.error(updatedSkillError);
      if (updatedSkill) {
        setActiveSkill(updatedSkill[0]);
        dispatch(setIsPageLoading(false));
      }

      // handle original image upload
      if (selectedSkillImage !== activeSkill?.original_Cover_image) {
        const response = await fetch(selectedSkillImage);
        const blobData = await response.blob();

        // Compress the image using browser-image-compression library
        const options = {
          maxSizeMB: 1, // Set the maximum size of the compressed image
          maxWidthOrHeight: 600, // Set the maximum width or height of the compressed image
          useWebWorker: true, // Use a web worker for faster compression
          quality: 1,
        };

        const compressedBlob = await imageCompression(blobData, options);

        const originalImageUrl = await uploadImage(
          compressedBlob,
          "original-cover-image-" +
            activeSkill.id +
            "-v-" +
            new Date().getTime()
        );

        const { error } = await supabase
          .from("creator_skills")
          .update({
            original_cover_image: originalImageUrl,
          })
          .eq("id", activeSkill?.id);

        if (error) console.error(error);

        const { data: updatedSkill, error: updatedSkillError } = await supabase
          .from("creator_skills")
          .select("*")
          .eq("id", activeSkill.id);

        if (updatedSkillError) console.error(updatedSkillError);
        if (updatedSkill) setActiveSkill(updatedSkill[0]);
      }
    } catch (error) {
      console.error("Error handling upload:", error);
    }
  };

  const handleCancel = () => {
    dispatch(updateProfileImageData({ croppedProfileImage: null }));
    dispatch(setSelectedSkillImage(null));
    dispatch(setIsModalSkillOpen(false));
  };

  return (
    <Box className={styles.parent}>
      <Box className={styles.container}>
        <ImageCropper onCrop={handleCrop} onCancel={handleCancel} />
      </Box>
    </Box>
  );
};

export default EditSkillImage;
