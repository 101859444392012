import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import Thanks from "../../components/registration/thanks";
import HorizontalLinearStepper from "../../components/registration/stepper";
import styles from "./style.module.css";
import useLocalStorageState from "../../hooks/useLocalStorageState";
import { useTheme } from "../../context/themeContext";
import useProtectedAuth from "../../hooks/useProtectedAuth";
import useOnboarding from "../../hooks/registeration/useOnboarding";
import { useSelector } from "react-redux";
import Loading from "../../components/loading/default";

function Onboarding() {
  useProtectedAuth();
  const { theme } = useTheme();
  const [isRegisterationFinished, setIsRegisterationFinished] =
    useLocalStorageState("registeration-finished", false);
  const {
    data: { completedInfo },
  } = useSelector((state) => state.registeration);
  const { pageLoading } = useSelector((state) => state.isLoading);

  const { handleDataComplete } = useOnboarding();

  useEffect(() => {
    if (completedInfo && isRegisterationFinished) return;
    if (!completedInfo && isRegisterationFinished)
      setIsRegisterationFinished(false);
  }, []);

  if (pageLoading) return <Loading />;

  return (
    <React.Fragment>
      {isRegisterationFinished && (
        <Thanks onboardingDataComplete={handleDataComplete} />
      )}
      {isRegisterationFinished === false && (
        <Box
          sx={{
            backgroundColor: theme.palette.lightgrey.lightgrey600,
            minHeight: "100vh",
            padding: "1.5rem",
          }}
        >
          <Box
            className={styles.parent}
            sx={{
              backgroundColor: theme.palette.lightgrey.lightgrey00,
            }}
          >
            <Box className={styles.basicLogo}>
              <Container className={styles.content}>
                <Box className={styles.text}>
                  <Typography
                    variant="body1"
                    align="center"
                    color={"#FFF"}
                    sx={{
                      fontSize: "36px",
                      fontWeight: "700",
                      lineHeight: "44px",
                      textTransform: "uppercase",
                      textAlign: "center",
                    }}
                  >
                    Creator Registration
                  </Typography>
                  <Typography variant="body1" align="center" color={"#FFF"}>
                    Apply in less than 60 seconds
                  </Typography>
                </Box>
              </Container>
            </Box>

            <Box className={styles.stepper}>
              <HorizontalLinearStepper
                setIsRegisterationFinished={setIsRegisterationFinished}
              />
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
}

export default Onboarding;
