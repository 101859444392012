import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import publicUserReducer from "./slices/profile/publicUserSlice";
import loadingReducer from "./slices/loadingSlice";
import registerationReducer from "./slices/registerationSlice";
import profileImageReducer from "./slices/profile/profileImageSlice";
import aboutEditReducer from "./slices/profile/aboutEditSlice";
import skillImageReducer from "./slices/profile/skillImageSlice";
import addSkillReducer from "./slices/profile/addSkillSlice";
import editSkillReducer from "./slices/profile/editSkillSlice";
import projectDetailsReducer from "./slices/profile/projectDetailsSlice";
import manageProjectsReducer from "./slices/profile/manageProjectsSlice";
import projectCoverImageReducer from "./slices/profile/projectCoverImageSlice";
import projectFiltersSlice from "./slices/profile/projectFiltersSlice";
import discoverReducer from "./slices/profile/discoverSlice";
import searchReducer from "./slices/profile/searchSlice";
import profileCompleteReducer from "./slices/profile/profileCompleteSlice";
import settingsReducer from "./slices/profile/settingsSlice";
import infoReducer from "./slices/infoSlice";
import rolesReducer from "./slices/profile/rolesSlice";

const store = configureStore({
  reducer: {
    // theme: themeReducer,
    isLoading: loadingReducer,
    user: userReducer,
    publicUser: publicUserReducer,
    // signup: signupReducer, // there is a custom hook
    registeration: registerationReducer,
    profileImage: profileImageReducer,
    aboutEdit: aboutEditReducer,
    skillImage: skillImageReducer,
    addSkill: addSkillReducer,
    editSkill: editSkillReducer,
    manageProjects: manageProjectsReducer, // There is a custom hook
    projectDetails: projectDetailsReducer,
    projectCoverImage: projectCoverImageReducer,
    projectFilters: projectFiltersSlice, // There is a custom hook with effects
    discover: discoverReducer, // There is a custom hook with effects
    search: searchReducer,
    profileComplete: profileCompleteReducer, // There is a custom hook with effects
    settings: settingsReducer, // There is a custom hook with effects
    info: infoReducer,
    roles: rolesReducer,
  },
});

export default store;
