import React, { useState, useRef } from "react";
import { getCroppedImg } from "./cropUtils"; // You'll need to implement this utility function
import styles from "./style.module.css";
import { Box, Typography } from "@mui/material";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import LoadingButton from "../../../../../../../components/loading/button";
import { useSelector } from "react-redux";

const ASPECT_RATIO = 700 / 735;
const MIN_DIMENSION = 200;

const ImageCropper = ({ onCrop, onCancel }) => {
  const { selectedSkillImage } = useSelector((state) => state.skillImage);
  const [isSkillUploading, setIsSkillUploading] = useState(false);

  const [crop, setCrop] = useState();
  const imgRef = useRef(null);

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );

    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const handleCrop = async () => {
    const croppedImage = await getCroppedImg(
      imgRef.current,
      convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
    );

    onCrop(croppedImage);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#040d12",
        height: "100%",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: {
            xs: "100%",
            md: "400px",
          },
          height: "100%",
        }}
      >
        {selectedSkillImage && (
          <Box className={styles.crop}>
            <ReactCrop
              crop={crop}
              onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
              keepSelection
              aspect={700 / 735}
              minWidth={MIN_DIMENSION}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  aspectRatio: "700 / 735",
                }}
              >
                <img
                  ref={imgRef}
                  src={selectedSkillImage}
                  alt="upload"
                  crossOrigin="anonymous"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  onLoad={onImageLoad}
                />
              </Box>
            </ReactCrop>
          </Box>
        )}
        <Box className={styles.frame}>
          <Box className={styles.content}>
            <Typography
              variant="body1"
              fontWeight={500}
              onClick={() => onCancel()}
              sx={{
                cursor: "pointer",
              }}
            >
              Cancel
            </Typography>
            <Typography
              variant="body1"
              fontWeight={500}
              onClick={handleCrop}
              sx={{
                cursor: "pointer",
              }}
            >
              <div onClick={() => setIsSkillUploading(true)}>
                {isSkillUploading ? <LoadingButton /> : "choose"}
              </div>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ImageCropper;
