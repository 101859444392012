import { Box, Typography } from "@mui/material";
import React from "react";
import { Close } from "@mui/icons-material";
import styles from "./style.module.css";
import { useTheme } from "../../../../../../../../../../context/themeContext";

const Head = ({ closeModal }) => {
  const { theme } = useTheme();

  const style = {
    bgcolor: theme.palette.lightgrey.lightgrey500,
  };

  return (
    <Box sx={style} className={styles.container}>
      <Box sx={style} className={styles.text}>
        <Box sx={style} className={styles.subText}>
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.darkgrey.darkgrey500,
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            Upload your profile image
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.darkgrey.darkgrey300 }}
          >
            JPG, GIF, PNG, ..etc. Max size of 2MB
          </Typography>
        </Box>
        <Close
          onClick={closeModal}
          sx={{
            cursor: "pointer",
            color: theme.palette.darkgrey.darkgrey300,
          }}
        />
      </Box>
    </Box>
  );
};

export default Head;
