import { StepConnector, stepConnectorClasses, styled } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import {
  AutoAwesome,
  MusicNote,
  Settings,
  SportsEsports,
} from "@mui/icons-material";
import { useTheme } from "../../context/themeContext";
import { useLocation } from "react-router-dom";

const useStepper = () => {
  const { theme } = useTheme();
  const { pathname } = useLocation();

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      display: "none",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 10,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ ownerState }) => ({
    zIndex: 1,
    color: theme.palette.darkgrey.darkgrey500,
    width: 50,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.completed && {
      color: theme.palette.darkgrey.darkgrey500,
    }),
  }));

  const ColorlibStepIcon = (props) => {
    const { active, completed, className } = props;
    let icons;

    if (pathname === "/onboarding") {
      icons = {
        1: <Settings />,
        2: <MusicNote />,
        3: <SportsEsports />,
        4: <AutoAwesome />,
      };
    } else {
      icons = {
        1: <MusicNote />,
        2: <SportsEsports />,
        3: <AutoAwesome />,
      };
    }

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  };

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  return { ColorlibStepIcon, ColorlibConnector };
};

export default useStepper;
