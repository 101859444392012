import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projects: [],
  hostedProjects: [],
  likedProjects: [],
  applications: [],
  projectApplicants: [],
  editNavigate: false,
  isAppModalOpen: false,
  isDrawerOpen: false,
};

const manageProjectsSlice = createSlice({
  name: "manageProjects",
  initialState,
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setHostedProjects: (state, action) => {
      state.hostedProjects = action.payload;
    },
    setLikedProjects: (state, action) => {
      state.likedProjects = action.payload;
    },
    setApplications: (state, action) => {
      state.applications = action.payload;
    },
    setProjectApplicants: (state, action) => {
      state.projectApplicants = action.payload;
    },
    setEditNavigate: (state, action) => {
      state.editNavigate = action.payload;
    },
    setIsAppModalOpen: (state, action) => {
      state.isAppModalOpen = action.payload;
    },
    setIsDrawerOpen: (state, action) => {
      state.isDrawerOpen = action.payload;
    },
  },
});

export const {
  setProjects,
  setHostedProjects,
  setLikedProjects,
  setApplications,
  setProjectApplicants,
  setEditNavigate,
  setIsAppModalOpen,
  setIsDrawerOpen,
} = manageProjectsSlice.actions;

export default manageProjectsSlice.reducer;
